import React, { Fragment, useEffect, useState } from "react";
import MobilePrivacy from "../Help/mobileFaq";
import PrivacyTable from "./PrivacyTable";

const PrivacyItem = (props) => {
  const { data, heading } = props.privacydataItem;
  const PrivacyData = data;
  const privacyHeading = heading;
  const [article, setArticle] = useState([]);
  useEffect(() => {
    setArticle(data[1]?.articles_list);
  }, []);

  return (
    <>
      <div className="container-wrap">
        <div className="privacy-wrapper privacy-grid">
          <aside>
            <ul>
              {PrivacyData?.map((item, index) => {
                const { nav_list } = item;
                return (
                  <Fragment key={index}>
                    {nav_list?.map((subItem, idx) => (
                      <>
                        <li key={idx}>
                          <a href={`#${subItem.target_name}`}>
                            {subItem.nav_item}
                          </a>
                        </li>
                      </>
                    ))}
                  </Fragment>
                );
              })}
            </ul>
          </aside>
          <div className="privacy-content-wrapper">
            <div className="privacy-heading">
              <h2>{privacyHeading?.title}</h2>
              <h5>{privacyHeading?.question}</h5>
              {privacyHeading?.sub_question_terms ? (
                <h4>{privacyHeading?.sub_question_terms}</h4>
              ) : (
                ""
              )}
              <p>{privacyHeading?.answer}</p>
              {privacyHeading?.sub_question ? (
                <h5>{privacyHeading?.sub_question}</h5>
              ) : (
                ""
              )}
            </div>
            <article className="article-wrapper desktop-privacy">
              {article?.map((articleItem, index) => {
                const { title, target_name, answer, id } = articleItem;
                return (
                  <div
                    className="article-item"
                    key={index}
                    id={`${target_name}`}
                  >
                    <div className="article-heading">
                      <h3>{`${id}. ${"  "} ${title}`}</h3>
                    </div>
                    <div className="article-answer terms-ans">
                      {answer?.map((list, index) => {
                        const {
                          heading,
                          answer_item,
                          sub_answer_item,
                          sub_answer_bold,
                          ans_id,
                          internal_bold,
                          ans_child,
                          ans_child_bold_first,
                          answer_item_child,
                          order_name,
                          table_data,
                          sub_answer_child_item,
                          order_list,
                          sub_answer_inline_bold,
                        } = list;
                        return (
                          <div key={index} className="privacy-items">
                            {heading ? (
                              <span className="article-ans-heading">
                                {" "}
                                {ans_id && `${ans_id}.`} {heading}
                              </span>
                            ) : (
                              ""
                            )}
                            {sub_answer_inline_bold && (
                              <b className="sub-bold">
                                {sub_answer_inline_bold} <>&nbsp;</>
                              </b>
                            )}
                            {ans_child && (
                              <span className="article-ans-content">
                                {ans_child}
                              </span>
                            )}
                            {ans_child_bold_first && (
                              <b className="sub-bold">
                                <>
                                  <>&nbsp;</>
                                </>
                                {ans_child_bold_first}
                              </b>
                            )}
                            {answer_item && (
                              <span className="article-ans-content">
                                {answer_item}
                                {sub_answer_item && (
                                  <>
                                    <br />
                                    {sub_answer_item}
                                  </>
                                )}
                                {sub_answer_bold && (
                                  <b>
                                    <br /> {sub_answer_bold} <br />
                                    <br />
                                  </b>
                                )}
                              </span>
                            )}
                            {/* table--section--start--here */}
                            {table_data && (
                              <PrivacyTable table_data={table_data} />
                            )}
                            {/* table--section--ene--here */}

                            {internal_bold && (
                              <span className="sub-bold">{internal_bold}</span>
                            )}
                            {answer_item_child && (
                              <span className="article-ans-content">
                                {answer_item_child}
                              </span>
                            )}
                            <ol
                              className={
                                order_name
                                  ? `${order_name} order-list`
                                  : "order-list"
                              }
                            >
                              {" "}
                              {order_list?.map((item, idx) => (
                                <li key={idx}>
                                  {item.order_item}
                                  {item.sub_order_item && (
                                    <>
                                      <p>{item.sub_order_item?.first}</p>
                                      <p>{item.sub_order_item?.two}</p>
                                      <p>{item.sub_order_item?.three}</p>
                                    </>
                                  )}
                                </li>
                              ))}{" "}
                            </ol>
                            {sub_answer_child_item && (
                              <span className="article-ans-content">
                                {sub_answer_child_item}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </article>
            <div className="mobile-privacy-wrapper">
              <MobilePrivacy  article={article} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyItem;
