import React, { useContext, useState, useEffect } from "react";
import { getToken, onMessageListener } from "../firebase";
import { arrayBufferToBase64 } from "../utils";

const argon2 = require("argon2-browser");
const sha512 = require("js-sha512");
const secureRandom = require("secure-random");

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [firebaseToken, setFirebaseToken] = useState("");
  const [challenge, setChallenge] = useState("");
  const [authSecret, setAuthSecret] = useState("");
  const [argonPassword, setArgonPassword] = useState("");
  const [byetPasswords, setByetPasswords] = useState("");
  const [isChallengeFound, setIsChallengeFound] = useState(false);
  const [isCaptchaShow, setIsCaptchaShow] = useState(false);
  var bytes = secureRandom(18); //return an Array of 18 bytes

  useEffect(() => {
    getToken(setFirebaseToken);
    // getMessages(setChallenge);
    getChallengeHandler();
  }, []);

  const getChallengeHandler = () => {
    onMessageListener()
      .then((payload) => {
        const { data } = payload;
        if (data.challenge) {
          setChallenge(data.challenge);
          setIsChallengeFound(true);
          setIsCaptchaShow(false);
        } else {
          setIsChallengeFound(false);
          setIsCaptchaShow(true);
        }
      })
      .catch((err) => {
        setIsChallengeFound(false);
        setIsCaptchaShow(true);
        console.log("failed: ", err);
      });
  };

  const refreshToken = () => {
    getToken(setFirebaseToken);
  };

  const setAuthSecretHandler = (mobile) => {
    let secret = mobile + ":" + arrayBufferToBase64(bytes);
    setAuthSecret(Buffer.from(secret).toString("base64"));
    // const isTokenValid = localStorage.getItem("isTokenValid");

    localStorage.setItem("secret", arrayBufferToBase64(bytes));
    localStorage.setItem("auth_secret", Buffer.from(secret).toString("base64"));
  };
  const setAuthSecretAfterLoginHandler = (id, deviceId) => {
    // let secret = id +"."+":" + localStorage.getItem("secret");
    let secret = `${id}.${deviceId}:${localStorage.getItem("secret")}`;
    setAuthSecret(Buffer.from(secret).toString("base64"));
    localStorage.setItem("auth_secret", Buffer.from(secret).toString("base64"));
  };

  const clearAuthSecretHeader = () => {
    localStorage.removeItem("auth_secret");
  };

  const getSH512Salt = (value) => {
    const bytes = sha512.digest(toUTF8Array(value));

    let keyBytes = new Int8Array(64);
    const arrayCopied = arrayCopy(bytes, 0, keyBytes, 0, keyBytes.length);

    //return salt;\
    return arrayCopied;
  };

  function arrayCopy(src, srcPos, dst, dstPos, length) {
    while (length--) dst[dstPos++] = src[srcPos++];
    return dst;
  }

  function toUTF8Array(str) {
    var bytes = []; // char codes
    var bytesv2 = []; // char codes

    for (var i = 0; i < str.length; ++i) {
      var code = str.charCodeAt(i);

      bytes = bytes.concat([code]);

      bytesv2 = bytesv2.concat([code & 0xff, (code / 256) >>> 0]);
    }

    setByetPasswords(bytes);
    return bytes;
  }

  const getArgon2Password = (salt) => {
    argon2
      .hash({
        pass: byetPasswords,
        salt: salt,
        time: 3,
        mem: 32 * 1024,
        hashLen: 32,
        parallelism: 1,
        type: argon2.ArgonType.Argon2id,
      })
      .then((h) => setArgonPassword(h.hashHex))
      .catch((e) => console.error(e.message, e.code));
  };

  const value = {
    firebaseToken,
    challenge,
    authSecret,
    byetPasswords,
    argonPassword,
    isChallengeFound,
    isCaptchaShow,
    setChallenge,
    getSH512Salt,
    getArgon2Password,
    toUTF8Array,
    setAuthSecretHandler,
    setAuthSecretAfterLoginHandler,
    setIsCaptchaShow,
    clearAuthSecretHeader,
    refreshToken,
    getChallengeHandler,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
