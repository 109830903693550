/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Avatar } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { handleProfile } from "./ProfileImageDecryption";

const ContributorsDetails = ({ contributor, index ,}) => {
  const [image, setImage] = useState("");
  useEffect(() => {
    const ProfileImage = async () => {
      try {
        const res =
          contributor.ua.length != 0 &&
          contributor.pk.length != 0 &&
          (await handleProfile(contributor.ua, contributor.pk));
        setImage(res); 
      } catch (error) {
        return error;
      }
    };
    ProfileImage();
    return () => ProfileImage();
  }, [image]);
 

  return (
    <>
      <li key={index}>
        <Avatar
          style={{ background: `${image.length > 0 && "none"}` }}
          size="xs"
          src={image}
          name={`${contributor?.fn} ${contributor?.ln}`}
        />
        <strong>@{contributor?.un}</strong>
      </li>
    </>
  );
};

export default ContributorsDetails;
