import { CustomModal } from "../../components/UI/Modal";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";
import PostUserLoginModal from "../../pages/PostUserLoginModal/PostUserLoginModal";
import { useState, useCallback } from "react";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";

const LoginModal = (props) => {
  const { show, handleClose, classValue, mainHeading } = props;
  const [loginValue, setLoginValue] = useState(false);

  const handleCloseSubmissionModal = useCallback(async () => {
    setLoginValue(false);
  }, []);
  return (
    <>
      <CustomModal
        className="login_share_post_modal"
        show={show}
        crossIcon={false}
        handleClose={handleClose}
      >
        <div className="share_post_wraper">
          <i className="bi bi-x" onClick={handleClose}></i>
          {/* <i
            className={`icon-md  ${value ? "login_to_like" : "comment_share"}`}
          /> */}
          <i className={`icon-md ${classValue}`} />
          <p className="login_text">{mainHeading}</p>
          <div className="download_image">
            <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
              <img
                src={PlayStore}
                width="123.15px"
                height="36.28px"
                alt="app store"
              />
            </a>
            <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
              <img
                width="123.15px"
                height="36.28px"
                src={AppStore}
                alt="app store"
              />
            </a>
          </div>
        </div>
      </CustomModal>

      <PostUserLoginModal
        show={loginValue}
        handleClose={handleCloseSubmissionModal}
      ></PostUserLoginModal>
    </>
  );
};

export default LoginModal;
