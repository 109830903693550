import React from "react";
import LogoImg from "../../../assets/img/logo.svg";

/**
 * @author
 * @function Logo
 **/

export const Logo = (props) => {
  return (
    <>
      <div className="logo_wrap">
        <img src={LogoImg} alt="logo" />
      </div>
    </>
  );
};
