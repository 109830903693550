import { Carousel } from "3d-react-carousal";
import StoryStatusItem from "../../pages/Feed/StoryStatusItem";

const Storystatus = (props) => {
  const { handleModalStatus, storiesList } = props;
  return (
    <Carousel
      slides={storiesList.map((Vditem) => (
        <StoryStatusItem
          handleModalStatus={handleModalStatus}
          Vditem={Vditem}
        />
      ))}
    />
  );
};

export default Storystatus;
