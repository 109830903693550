import moment from "moment";
import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * @author
 * @function Footer
 **/

export const Footer = (props) => {
  const { fromEarly } = props;
  return (
    <>
      <div className="bottom_nav">
        <Nav>
          <Nav.Item>
            {fromEarly && (
              <Link className="nav-link" to="/">
                Home
              </Link>
            )}
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/about">
              About
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/feature">
              Feature
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/jobs">
              Jobs
            </Link>
          </Nav.Item>
          {!fromEarly && (
            <>
              <Nav.Item>
                <Link className="nav-link" to="/help">
                  Help
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/privacy">
                  Privacy
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/terms">
                  Terms
                </Link>
              </Nav.Item>
            </>
          )}
        </Nav>
        <h5>&copy; {moment(new Date()).format("YYYY")} Cachy</h5>
      </div>
    </>
  );
};
