import React from "react";
import { Stack, Skeleton } from "@chakra-ui/react";

export default function TagsSkeleton() {
  return (
    <>
      <Stack mb="5">
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    </>
  );
}
