import { Box, Checkbox, Stack, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
// import { Button, Container, FormControl, InputGroup } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar";

import { useHistory } from "react-router-dom";
// import isEmpty from "is-empty";
import { getAllJobs, getJobsByFilter, jobfilter } from "./Service";
import JobListCard from "./JobListCard";
import { getWindowDimensions } from "../../utils";
import JobCardSkeleton from "../../components/Skeleton/JobCard";
import JobFilterSkeleton from "../../components/Skeleton/JobFilterSkeleton";

function Jobs() {
  const history = useHistory();
  const [jobFilterList, setJobFilterList] = useState({});
  const [allJobsList, setAllJobsList] = useState([]);
  const [filterBy, setFilterBy] = useState({
    department: [],
    jobtype: [],
    search: "",
  });

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isLoading, setLoading] = useState(false);
  const [isFilterLoading, setFilterLoading] = useState(false);

  const handleResetAll = () => {
    setFilterBy({
      department: [],
      jobtype: [],
      search: "",
    });
    getAllJobsHandler();
  };

  // const handleSearchJob = (e) => {
  //   const { name, value } = e.target;
  //   setFilterBy((prev) => ({ ...prev, [name]: value }));

  //   if (isEmpty(value)) {
  //     getAllJobsHandler();
  //   }
  // };

  const handleFilterChange = (e, type, value) => {
    if (e.target.checked) {
      setFilterBy((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], value],
      }));
    } else {
      setFilterBy((prevState) => ({
        ...prevState,
        [type]: filterBy[type].filter((p) => p !== value),
      }));
    }
  };

  const jobfilterHandler = async () => {
    setFilterLoading(true);
    const res = await jobfilter();
    if (res.code === 200) {
      setJobFilterList(res.data);
      setFilterLoading(false);
    }
  };

  const getAllJobsHandler = async () => {
    setLoading(true);
    const res = await getAllJobs();
    if (res.code === 200) {
      setAllJobsList(res.data);
      setLoading(false);
    }
  };

  const getJobsByFilterHandler = useCallback(async () => {
    setLoading(true);
    const { department, jobtype } = filterBy;
    const payload = {
      department,
      jobtype,
    };
    const res = await getJobsByFilter(payload);

    if (res.code === 200) {
      setAllJobsList(res.data);
      setLoading(false);
    }
  }, [filterBy]);

  useEffect(() => {
    jobfilterHandler();
    getAllJobsHandler();
  }, []);

  useEffect(() => {
    if (filterBy.department.length > 0 || filterBy.jobtype.length > 0) {
      getJobsByFilterHandler();
    } else {
      getAllJobsHandler();
    }
  }, [
    filterBy.department.length,
    filterBy.jobtype.length,
    getJobsByFilterHandler,
  ]);

  const filterCollapseHandler = () => {
    if (windowDimensions.width < 767) {
      setFilterOpen(!isFilterOpen);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <PublicNavbar />
      <div
        className={`public_wrapper help jobs_wrapper ${
          isFilterOpen ? "filter_open" : ""
        }`}
      >
        <h1>Jobs</h1>
        <div className="body">
          <Container>
            {/* <Box className="head_box">
              <form onSubmit={onSearchHandler}>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="bi bi-search"></i>
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Search for jobs"
                    name="search"
                    onChange={handleSearchJob}
                  />
                  <InputGroup.Text>
                    <Button variant="primary" type="submit" size="sm">
                      search
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </form>
              <Text className="view_job_text">
                viewing {allJobsList.length} jobs related to
              </Text>
            </Box> */}

            <Box display="flex" className="filter_text_top_box">
              <Box className="filter_text_top_left_box">
                <Text className="filter_text" onClick={filterCollapseHandler}>
                  Filter
                </Text>
                <Text className="reset_text" onClick={handleResetAll}>
                  Reset all
                </Text>
              </Box>
              <Box flex={1}></Box>
            </Box>

            <Box className="jobs_body_box">
              <Box className="filter_left_box">
                <Text className="title_text">By Department</Text>
                <Stack spacing={2} marginBottom="40px">
                  {isFilterLoading && (
                    <Box mb="10">
                      <JobFilterSkeleton lines={4} />
                    </Box>
                  )}

                  {jobFilterList?.department?.map((job, index) => (
                    <Checkbox
                      key={index}
                      onChange={(e) => handleFilterChange(e, "department", job)}
                      isChecked={filterBy.department.includes(job)}
                    >
                      {job}
                    </Checkbox>
                  ))}
                </Stack>
                <Text className="title_text">By Type</Text>
                <Stack spacing={2}>
                  {isFilterLoading && (
                    <Box>
                      <JobFilterSkeleton lines={2} />
                    </Box>
                  )}
                  {jobFilterList?.job_type?.map((job, index) => (
                    <Checkbox
                      key={index}
                      onChange={(e) => handleFilterChange(e, "jobtype", job)}
                      isChecked={filterBy.jobtype.includes(job)}
                    >
                      {job
                        .split(" ")
                        .map((word) => {
                          return word[0].toUpperCase() + word.substring(1);
                        })
                        .join(" ")}
                    </Checkbox>
                  ))}
                </Stack>
              </Box>
              <Box className="jobs_list_box">
                <ul>
                  {isLoading ? (
                    <>
                      <li>
                        <JobCardSkeleton />
                      </li>
                      <li>
                        <JobCardSkeleton />
                      </li>
                      <li>
                        <JobCardSkeleton />
                      </li>
                    </>
                  ) : (
                    <>
                      {" "}
                      {allJobsList.map((job, index) => (
                        <li
                          key={index}
                          onClick={() => history.push(`/job/${job.id}`)}
                        >
                          <JobListCard data={job} />
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
      <Footer extraItems />
    </>
  );
}

export default Jobs;
