import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { Upload } from "../../components/UploadFile";
import { jobApply, uploadFile } from "./Service";
import isEmpty from "is-empty";
import validator from "validator";

function ApplyForm(props) {
  const { jobId, onClose } = props;
  const toast = useToast();

  const [apllicantDetails, setApplicantDetails] = useState({});
  const [uploading, setUploading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMobile, setErrorMobile] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (!validator.isMobilePhone(value, "en-IN")) {
        setErrorMobile("Invalid Mobile");
      } else {
        setErrorMobile("");
      }
    } else if (name === "email") {
      if (!validator.isEmail(value)) {
        setErrorEmail("Invalid Email");
      } else {
        setErrorEmail("");
      }
    }
    if (!value) {
      setErrorMobile("");
      setErrorEmail("");
    }
    setApplicantDetails((prev) => ({ ...prev, [name]: value }));
  };

  const uploadFileHandler = async (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("image", file);
    const res = await uploadFile(formData);
    if (res.data) {
      setApplicantDetails((prev) => ({
        ...prev,
        resume: res.data.url,
        fileName: res.data.name,
      }));
      setUploading(false);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      firstName,
      lastName,
      email,
      mobile,
      expectedCTC,
      noticPeriod,
      resume,
      highestQualification,
      experience,
      skills,
      previousCompany,
      currentLocation

    } = apllicantDetails;
    const payload = {
      job_id: jobId,
      firstname: firstName,
      lastname: lastName,
      mobile: mobile,
      email: email,
      resume: resume,
      notice_period: noticPeriod,
      expected_ctc: expectedCTC,
      highest_qualification :highestQualification,
      experience:experience,
      skills:skills,
      previous_company:previousCompany,
      current_location:currentLocation

    };
    const res = await jobApply(payload);
   
    if (res.code === 200) {
      toast({
        title: "Job Applied",
        description: "Thank you for applying.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      onClose();
    } else {
      toast({
        title: "Error",
        description: res.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const isButtonDisabled = () => {
    let isValid = true;

    if (
      !isEmpty(apllicantDetails?.firstName) &&
      !isEmpty(apllicantDetails?.mobile) &&
      !isEmpty(apllicantDetails?.email) &&
      !isEmpty(apllicantDetails?.expectedCTC) &&
      !isEmpty(apllicantDetails?.noticPeriod) &&
      !isEmpty(apllicantDetails?.resume)
    ) {
      isValid = false;
    }

    if (isLoading || errorMobile || errorEmail) {
      isValid = true;
    }

    return isValid;
  };

  return (
    <>
      <Box className="apply_job_box">
        <Form onSubmit={onSubmitHandler}>
          <Box maxHeight="390px" overflowY="auto" px="5">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="Enter first name"
                onChange={handleChange}
                value={apllicantDetails?.firstName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Enter last name"
                onChange={handleChange}
                value={apllicantDetails?.lastName}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                onChange={handleChange}
                value={apllicantDetails?.email}
              />
              {errorEmail && <Text color="red">{errorEmail}</Text>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                name="mobile"
                type="number"
                placeholder="Enter mobile number"
                onChange={handleChange}
                value={apllicantDetails?.mobile}
              />
              {errorMobile && <Text color="red">{errorMobile}</Text>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Expected CTC</Form.Label>
              <Form.Control
                name="expectedCTC"
                type="text"
                placeholder="Enter expected ctc"
                onChange={handleChange}
                value={apllicantDetails?.expectedCTC}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Notice Period</Form.Label>
              <Form.Control
                name="noticPeriod"
                type="text"
                placeholder="Enter notice period"
                onChange={handleChange}
                value={apllicantDetails?.noticPeriod}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Highest Qualification</Form.Label>
              <Form.Control
                name="highestQualification"
                type="text"
                placeholder="Enter your Highest Qualification"
                onChange={handleChange}
                value={apllicantDetails?.highestQualification}
              />
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Experience</Form.Label>
              <Form.Control
                name="experience"
                type="text"
                placeholder="Enter your Experience"
                onChange={handleChange}
                value={apllicantDetails?.experience}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Skills</Form.Label>
              <Form.Control
                name="skills"
                type="text"
                placeholder="Enter your Skills"
                onChange={handleChange}
                value={apllicantDetails?.skills}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Previous Company</Form.Label>
              <Form.Control
                name="previousCompany"
                type="text"
                placeholder="Enter your Previous Company"
                onChange={handleChange}
                value={apllicantDetails?.previousCompany}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Current Location</Form.Label>
              <Form.Control
                name="currentLocation"
                type="text"
                placeholder="Enter your Current Location"
                onChange={handleChange}
                value={apllicantDetails?.currentLocation}
              />
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Upload CV</Form.Label>

              <Upload
                onDrop={(files) => {
                  uploadFileHandler(files[0]);
                }}
                accept=".doc,.docx,.pdf"
                isUploading={uploading}
                fileName={apllicantDetails?.fileName}
              />
            </Form.Group>
          </Box>

          <div className="button_wrap">
            <Button
              isLoading={isLoading}
              loadingText="Submitting"
              type="submit"
              disabled={isButtonDisabled()}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Box>
    </>
  );
}

export default ApplyForm;
