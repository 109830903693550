import {
  Avatar,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { CustomModal } from "../../components/UI/Modal";
import ContributorsDetailsInner from "../../pages/Feed/ContributorsDetailsInner";
import { COPY_BASE_URL, FEED_IMG_URL } from "../../utils/config";
import CustomInput from "../Feed/CustomInput";
import LoginModal from "../LoginModal/LoginModal";
import PostUserLoginModal from "../PostUserLoginModal/PostUserLoginModal";
const LoginPostModal = ({ handleCloseModal, showModal, post }) => {
  const [submissionModal, setsubmissionModal] = useState(false);
  const [submissionValue, setsubmissionValue] = useState(false);
  const [resdata, setResData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [videoPoster, setVideoPoster] = useState("");
  const [postLoginValueModal, setpostLoginValueModal] = useState(false);
  const [comment, setComment] = useState("");

  const [classValue, setClassValue] = useState("");
  const [mainHeading, setMainHeading] = useState("");
  const handleCloseSubmissionModal = useCallback(async () => {
    setsubmissionModal(false);
    setpostLoginValueModal(false);
  }, []);
  const toast = useToast();
  const redirectToLogin = (heading, classValue) => {
    setMainHeading(heading);
    setClassValue(classValue);
    setsubmissionModal(true);
    // setsubmissionValue(value);
  };

  const redirectToPostLogin = () => {
    setpostLoginValueModal(true);
  };

  const id = "test-123s";

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <>
      <CustomModal
        handleClose={handleCloseModal}
        className="login-modal"
        show={showModal}
      >
        <div
          className="post_share_modal share-post-wrap"
          // onClick={handleClick}
          id="parent"
        >
          <div className="share-post-container">
            <div
              className="player_modal share_post_size"
              style={{ height: `${isSafari ? "802px" : "791px"}` }}
            >
              <div className="player_row">
                <div className="player_col left">
                  <div className="player-wrapper">
                    {!isSafari ? (
                      <video
                        className="react-player"
                        style={{
                          height: `${isSafari ? "none" : "100%"}`,
                        }}
                        autoPlay
                        playsInline
                        controls
                        src={`${FEED_IMG_URL}${post?.video?.remoteid}`}
                        poster={`${FEED_IMG_URL}${post?.coverImg?.remoteid}`}
                      ></video>
                    ) : (
                      <video
                        className="react-player"
                        style={{
                          height: `${isSafari ? "none" : "100%"}`,
                        }}
                        autoPlay
                        playsInline
                        controls
                        poster={videoPoster}
                      >
                        <source
                          src={`${FEED_IMG_URL}${post?.video?.remoteid}`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                </div>
                <div className="player_col right">
                  <div
                    onClick={() =>
                      redirectToLogin(
                        "Follow the Creators on Cachy Mobile",
                        "follow_share"
                      )
                    }
                    className="head"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      size="lg"
                      style={{
                        cursor: "pointer",
                        // background: `${image ? "none" : ""}`,
                      }}
                      //   src={image}
                      name={post?.firstName + " " + post?.lastName}
                    />
                    <div className="media_details">
                      <h2>
                        {post?.firstName} {post?.lastName}
                      </h2>
                      <p>@{post?.userName}</p>
                    </div>
                  </div>

                  <div className="body">
                    <p className="metadata">{post?.metadata}</p>
                    <p className="text-conti">Contributors</p>
                    <ul
                      onClick={() =>
                        redirectToLogin(
                          "Follow the Creators on Cachy Mobile",
                          "follow_share"
                        )
                      }
                    >
                      {post?.contributorsDetails?.map((c, index) => (
                        <ContributorsDetailsInner key={index} contributor={c} />
                      ))}
                    </ul>
                  </div>
                  <div className="feed_bottom">
                    <ul>
                      <i
                        className={`icon  ${
                          post?.isLiked ? "liked heart_anim" : "like"
                        }`}
                        onClick={() => {
                          redirectToLogin(
                            "Share the Love via Cachy Mobile.",
                            "login_to_like"
                          );
                        }}
                      ></i>

                      <i
                        className="icon comment"
                        onClick={() => {
                          redirectToLogin(
                            "Join the Conversation via Cachy Mobile.",
                            "comment_share"
                          );
                        }}
                      ></i>

                      <Popover
                        isLazy
                        className="popover_share"
                        placement="top"
                        arrowSize="15"
                        boundary="none"
                      >
                        <PopoverTrigger>
                          <i className="icon share"></i>
                        </PopoverTrigger>
                        <PopoverContent
                          className="popover_content"
                          style={{
                            width: "100%",
                            borderRadius: "30px",
                            boxShadow: "none",
                          }}
                        >
                          <PopoverArrow
                            style={{ borderBottomRightRadius: "4px" }}
                          />
                          <div
                            className="icon_list"
                            style={{ border: "none", padding: "8px" }}
                          >
                            <FacebookShareButton
                              style={{
                                height: "16px",
                                padding: "0.3rem",
                              }}
                              url={`${COPY_BASE_URL}feed/${post.postId}`}
                            >
                              {/* <i className="icon_sm facebook_md_share"></i> */}
                              <i
                                className="fab fa-facebook-f"
                                title="facebook"
                              ></i>
                            </FacebookShareButton>
                            <TwitterShareButton
                              style={{
                                height: "16px",
                                padding: "0.3rem",
                              }}
                              url={`${COPY_BASE_URL}feed/${post.postId}`}
                            >
                              <i className="fab fa-twitter" title="twitter"></i>
                            </TwitterShareButton>
                            <LinkedinShareButton
                              style={{
                                height: "16px",
                                padding: "0.3rem",
                              }}
                              url={`${COPY_BASE_URL}feed/${post.postId}`}
                            >
                              <i className="fab fa-linkedin"></i>
                            </LinkedinShareButton>
                            <EmailShareButton
                              style={{
                                height: "16px",
                                padding: "0.3rem",
                              }}
                              url={`${COPY_BASE_URL}feed/${post.postId}`}
                            >
                              {" "}
                              <i className="far fa-envelope"></i>{" "}
                            </EmailShareButton>
                            <button>
                              <i
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    `${COPY_BASE_URL}feed/${post.postId}`
                                  );
                                  if (!toast.isActive(id)) {
                                    toast({
                                      id,
                                      position: "top",
                                      title: "Copied",
                                      status: "success",
                                      duration: 3000,
                                      isClosable: true,
                                    });
                                  }
                                }}
                                style={{
                                  padding: "0.3rem",
                                }}
                                className="fas fa-link"
                              ></i>
                            </button>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </ul>
                    {/* <p className="like_comment_details">
                {post?.isLiked ? (
                  <>
                    Liked by
                    <strong>
                      {post?.isLiked ? " You " : ""}
                      {post?.likesCount > 0 && (
                        <>
                          and {post?.likesCount}{" "}
                          {post?.likesCount > 1
                            ? "others"
                            : "other"}
                        </>
                      )}
                    </strong>
                  </>
                ) : (
                  <>
                    {post?.likesCount > 0 && (
                      <>
                        <p>
                          {
                            // eslint-disable-next-line eqeqeq
                            post?.likesCount == 1
                              ? `${post?.likesCount} Like`
                              : `${post?.likesCount} Likes`
                          }
                        </p>
                      </>
                    )}
                  </>
                )}
              </p>
              {post?.commentsCount ? (
                <p
                  onClick={() => {
                    redirectToLogin(
                      "Join the Conversation via Cachy Mobile.",
                      "comment_share"
                    );
                  }}
                  className="view-all-comment"
                >
                  {`View all  ${post?.commentsCount}  comments`}
                </p>
              ) : (
                ""
              )} */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                      className="share-post-comment-wrap"
                    >
                      <div
                        className="comment_body"
                        style={{
                          height: "25.7rem",
                          width: "100%",
                        }}
                      >
                        <ul className="comment_ul"></ul>
                      </div>
                      <div
                        onClick={() => {
                          redirectToLogin(
                            "Join the Conversation via Cachy Mobile.",
                            "comment_share"
                          );
                        }}
                      >
                        <CustomInput
                          redirectToPostLogin={redirectToPostLogin}
                          sharePost
                          comment={comment}
                          setComment={setComment}
                          playerData={resdata?.data}
                          notLogin
                          isDisabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <LoginModal
        handleClose={handleCloseSubmissionModal}
        show={submissionModal}
        value={submissionValue}
        classValue={classValue}
        mainHeading={mainHeading}
      ></LoginModal>

      <PostUserLoginModal
        handleClose={handleCloseSubmissionModal}
        show={postLoginValueModal}
      ></PostUserLoginModal>
    </>
  );
};

export default LoginPostModal;
