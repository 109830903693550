/* eslint-disable eqeqeq */
import { Avatar } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { useParams, useLocation } from "react-router-dom";
import { useUser } from "../../context/UserProvider";
import { handleProfile } from "../../pages/Feed/ProfileImageDecryption";

const UserList = (props) => {
  const location = useLocation();
  const {
    data,
    onUnfollow,
    onFollow,
    fromFollowing,
    fromFollowers,
    fromSuggestion,
    handleUserStatus,
    handleCloseShowModalFollower,
    handleCloseShowModalFollowing,
  } = props;
  const { username } = useParams();
  const { userData } = useUser();
  const isOwnProfile = username
    ? userData?.profile?.username === username
    : location.pathname.includes("feed");

  const [image, setImage] = useState("");

  useEffect(() => {
    const ProfileImage = async () => {
      try {
        if (data.avatar && data.profileKey) {
          const res = await handleProfile(data.avatar, data.profileKey);
          setImage(res);
        }
      } catch (error) {
        return error;
      }
    };
    ProfileImage();
    return () => ProfileImage();
  }, [data.avatar, data.profileKey]);

  const handleClick = async () => {
    handleUserStatus(data);
    handleCloseShowModalFollower && handleCloseShowModalFollower();
    handleCloseShowModalFollowing && handleCloseShowModalFollowing();
  };

  return (
    <>
      <li>
        <div className="media">
          <div style={{ position: "relative", width: "56px" }}>
            <CircularProgressbarWithChildren
              style={{ position: "absolute" }}
              value={100}
              strokeWidth={3}
              styles={buildStyles({
                pathColor: !data.isUserStoryExists ? "none" : "#169D59",
                trailColor: "none",
              })}
            >
              <Avatar
                style={{ cursor: "pointer", background: `${image && "none"}` }}
                onClick={handleClick}
                size="md"
                src={image}
                round="true"
                maxinitials={2}
                name={`${data.firstName} ${data.lastName}`}
              />
            </CircularProgressbarWithChildren>
          </div>
          <div className="details">
            <p className="username" onClick={handleClick}>
              @{data?.username}
              {data.premium ? (
                <i
                  style={{ marginLeft: "5px" }}
                  className="icon_sm premium"
                ></i>
              ) : !data.professional ? (
                ""
              ) : data.professional == 1 ? (
                <i style={{ marginLeft: "5px" }} className="icon_sm expert"></i>
              ) : (
                <i
                  style={{ marginLeft: "5px" }}
                  className="icon_sm professional"
                ></i>
              )}
            </p>
            <h5 className="full_name" onClick={handleClick}>
              {data?.firstName} {data?.lastName}
            </h5>
          </div>
        </div>

        {data.uuid != userData.uuid && (
          <>
            {fromFollowers &&
              (isOwnProfile ? (
                <Button onClick={() => onUnfollow(data.uuid)}>Remove</Button>
              ) : data.isFollow ? (
                data.isFollow == 1 ? (
                  <Button onClick={() => onUnfollow(data.uuid)}>
                    Following
                  </Button>
                ) : (
                  <Button>Requested</Button>
                )
              ) : (
                <Button
                  onClick={() => onFollow(data.uuid, data.privateAccount)}
                  className="follow_btn isFollowing"
                >
                  Follow
                </Button>
              ))}
            {fromFollowing &&
              (data.isFollow == 0 ? (
                <Button
                  onClick={() => onFollow(data.uuid, data.privateAccount)}
                  className="follow_btn isFollowing"
                >
                  Follow
                </Button>
              ) : data.isFollow == 1 ? (
                <Button onClick={() => onUnfollow(data.uuid)}>Following</Button>
              ) : data.isFollow == 2 ? (
                <Button>Requested</Button>
              ) : (
                <Button>Requested</Button>
              ))}
            {fromSuggestion &&
              (data.isFollow ? (
                data.isFollow == 1 ? (
                  <Button onClick={() => onUnfollow(data.uuid)}>
                    Following
                  </Button>
                ) : (
                  <Button>Requested</Button>
                )
              ) : (
                <Button
                  onClick={() => onFollow(data.uuid, data.privateAccount)}
                  className="follow_btn isFollowing"
                >
                  Follow
                </Button>
              ))}
          </>
        )}
      </li>
    </>
  );
};

export default UserList;
