import React, { useContext, useState } from "react";

const FeedContext = React.createContext();

export function useFeed() {
  return useContext(FeedContext);
}

export function FeedProvider({ children }) {
  const [commentsOnPost, setCommentsOnPost] = useState([]);

  const onSubmitCommentsOnPost = (data) => {
    setCommentsOnPost(data);
  };

  const value = {
    commentsOnPost,
    onSubmitCommentsOnPost,
  };

  return <FeedContext.Provider value={value}>{children}</FeedContext.Provider>;
}
