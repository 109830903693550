import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Footer } from "../../components/shared/Footer";
//import PublicNavbar from "../../components/shared/PublicNavbar";
import { getPublicPageContent } from "../../publicApiCalls/Service";
// import isEmpty from "is-empty";
// import PublicPageSkeleton from "../../components/Skeleton/PublicPage";
import {
  Box,
  Heading,
  Text,
  // SimpleGrid,
  // Image,
  // List,
  // ListItem,
  // ListIcon,
  //useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
// import { MdChevronRight } from "react-icons/md";
// import ReactPlayer from "react-player";
import { Logo } from "../auth/shared/Logo";
//import FeedImg from "../../assets/img/Feeds.svg";

export default function About() {
  // eslint-disable-next-line no-unused-vars
  const [contentData, setContentData] = useState({});
  //const [isMobile] = useMediaQuery("(max-width: 480px)");
  const getPrivacyPolicyContentHanlder = async () => {
    const res = await getPublicPageContent("about");
    if (res.code === 200) {
      setContentData(res.data);
    }
  };

  useEffect(() => {
    getPrivacyPolicyContentHanlder();
  }, []);
  return (
    <>
      <Box className="about_box">
        <Link to="/">
          <Logo />
        </Link>
        <Box className="banner_video">
          <Box className="content_box">
            <Slide bottom>
              <Text className="mission_text">Mission</Text>
            </Slide>
            <Slide bottom cascade>
              <Text className="mission_heading">Present the Cachy</Text>
              <Text className="mission_heading">Side of the World</Text>
              <Text className="mission_heading">Encourage the</Text>
              <Text className="mission_heading"> World to be Cachy</Text>
              <Text className="mission_pointer">
                *Cachy is Synonym to Inner Beauty
              </Text>
            </Slide>
          </Box>
        </Box>
        <Box className="box_section">
          <Container>
            <Slide bottom>
              <Heading className="heading">ABOUT CACHY</Heading>
            </Slide>
            <Slide bottom>
              <Text>
                {/* Cachy is a beyond flash Social Network that let the World
                Connect, build Communities, be Inspired and be Admired via
                Conversations. Imagine a virtual world of reality, a world where
                the real essence comes out, of ordinary people like us, of
                people that have risen above the ordinary, of people that we
                often hear about, we admire them on-screen, we adore them in
                public appearances, and yet, they seem to be so far away and so
                different! In comes, Cachy! The virtual world, that connects us
                with reality, with real people, with real emotions behind the
                filters we are so accustomed to see around. Cachy brings with
                itself the aspect of raw experience with people afar. */}
                “Cachy” (pronounced catchy) is a Beyond Flash Social Network
                that enables the World to Connect, find Interesting People, be
                Inspired and feel Validated via the most Behavior reflecting,
                Non Aesthetical and Daily created content format :
                CONVERSATIONS! Built with Love and in No disrespect to existing
                platforms or content creators “Cachy” is simply an attempt to
                provide tools and a platform that encourages the World to Talk
                Good, Open the World to Information and Celebrate users for
                being Raw, Real or being Cachy.
              </Text>
            </Slide>
          </Container>
        </Box>
        {/* <Box className="box_section gray_bg">
          <Container>
            <SimpleGrid columns={isMobile ? 1 : 2} spacing={10}>
              <Box>
                <Slide bottom>
                  <Image src={FeedImg} alt="feed" />
                </Slide>
              </Box>
              <Box className="box_section_content">
                <Box>
                  <Slide bottom>
                    <Text className="top_text">Lorem Ipsum</Text>
                  </Slide>
                  <Slide bottom>
                    <Text className="title_text">
                      Lorem Ipsum has been the industry's
                    </Text>
                  </Slide>
                  <Slide bottom>
                    <Text>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It ha
                    </Text>
                  </Slide>
                </Box>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
        <Box className="box_section ">
          <Container>
            <SimpleGrid columns={isMobile ? 1 : 2} spacing={10}>
              <Box className="box_section_content">
                <Box>
                  <Slide bottom>
                    <Text className="top_text">Lorem Ipsum</Text>
                  </Slide>
                  <Slide bottom>
                    <Text className="title_text">
                      Lorem Ipsum has been the industry's
                    </Text>
                  </Slide>
                  <Slide bottom>
                    <Text>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It ha
                    </Text>
                  </Slide>
                </Box>
              </Box>
              <Box>
                <Slide bottom>
                  <Image src={FeedImg} alt="feed" />
                </Slide>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
        <Box className="box_section gray_bg">
          <Container>
            <SimpleGrid columns={isMobile ? 1 : 2} spacing={10}>
              <Box>
                <Slide bottom>
                  <Image src={FeedImg} alt="feed" />
                </Slide>
              </Box>
              <Box className="box_section_content">
                <Box>
                  <Slide bottom>
                    <Text className="top_text">Lorem Ipsum</Text>
                  </Slide>
                  <Slide bottom>
                    <Text className="title_text">
                      Lorem Ipsum has been the industry's
                    </Text>
                  </Slide>
                  <Slide bottom>
                    <Text>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It ha
                    </Text>
                  </Slide>
                </Box>
              </Box>
            </SimpleGrid>
          </Container>
        </Box> */}
        {/* <Box className="box_section gray_bg">
          <Container>
            <Slide bottom>
              <Heading className="heading" textAlign={"center"}>
                RESOURCES
              </Heading>
            </Slide>

            <Box className="resources_box">
              <List spacing={3}>
                <Slide bottom>
                  <ListItem>
                    About Cachy
                    <ListIcon as={MdChevronRight} />
                  </ListItem>
                </Slide>
                <Slide bottom>
                  <ListItem>
                    Feature
                    <ListIcon as={MdChevronRight} />
                  </ListItem>
                </Slide>
                <Slide bottom>
                  <ListItem>
                    Help
                    <ListIcon as={MdChevronRight} />
                  </ListItem>
                </Slide>
                <Slide bottom>
                  <ListItem>
                    Jobs
                    <ListIcon as={MdChevronRight} />
                  </ListItem>
                </Slide>

               
              </List>
            </Box>
          </Container>
        </Box> */}
        <Footer fromAbout extraItems />
      </Box>
    </>
  );
}
