import { Avatar } from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { useUser } from "../../context/UserProvider";
import { handleProfile } from "../../pages/Feed/ProfileImageDecryption";

const AvatarImage = ({ size, sharePost }) => {
  const [image, setImage] = useState("");

  const { userData, userProfileData } = useUser();

  const ProfileImage = useCallback(async () => {
    try {
      if (userProfileData?.avatar && userData?.profileKey) {
        const res = await handleProfile(
          userProfileData?.avatar,
          userData?.profileKey
        );
        setImage(res);
      }
    } catch (error) {
      return error;
    }
  });

  useEffect(() => {
    ProfileImage();
    return () => ProfileImage();
  }, [userProfileData?.avatar, userData?.profileKey, image]);

  return (
    <Avatar
      style={{ background: `${image.length > 0 && "none"}` }}
      size={size}
      src={image}
      name={
        !sharePost &&
        `${userProfileData?.firstName} ${userProfileData?.lastName}`
      }
    />
  );
};

export default AvatarImage;
