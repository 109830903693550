import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router";

import { useLocation, Redirect } from "react-router-dom";

import { AppNavbar } from "../shared/AppNavbar";

import { FeedProvider } from "../../context/FeedProvider";
import { Container } from "react-bootstrap";
import EditProfile from "../../pages/Profile/EditProfile";
import PrivacySettings from "../../pages/PrivacySettings";
import HelpAndSupport from "../../pages/HelpAndSupport";

import Feed from "../../pages/Feed";
import LeftFooter from "../shared/LeftFooter";
import LoginModal from "../../pages/LoginModal/LoginModal";
import { getUserProfileByUsername } from "../../pages/Profile/Service";
import BrokenPage from "../shared/BrokenPage";
import VerticalNav from "../shared/VerticalNav";
import { accountSearch, postSearch } from "../../pages/Feed/Service";
import SearchMain from "../../pages/Feed/GlobalSearch/SearchMain";
const ProfileCard = lazy(() => import("../shared/ProfileCard"));
const Profile = lazy(() => import("../../pages/Profile"));
const SuggestionListOther = lazy(() => import("../shared/SuggestionListOther"));

const PrivateLayout = () => {
  let location = useLocation();

  const FeedRender = () => {
    const [error, setError] = useState(false);
    const [accountValue, setAccountValue] = useState();
    const [postValue, setPostValue] = useState([]);
    const [close, setClose] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const handleMismatchRoute = async () => {
      const splitedUrl = window.location.href.split("/");
      switch (splitedUrl[3]) {
        case "feed":
          break;
        case "edit":
          break;
        case "edit-privacy-settings":
          break;
        case "edit-help-support":
          break;
        default:
          const res = await getUserProfileByUsername(splitedUrl[3]);
          if (res?.response?.status === 435) {
            setError(true);
          }
          break;
      }
    };

    useEffect(() => {
      handleMismatchRoute();
    }, []);

    const handleSearch = async (e) => {
      const accountData = `${e.target?.value}`;
      const param = `${e.target?.value}`;
      setSearchValue(e.target?.value);

      if (e.target.value?.length > 1) {
        try {
          setLoading(true);
          const data = await accountSearch(accountData);
          const postData = await postSearch(param);
          setAccountValue(data);
          setPostValue(postData?.data);
          setLoading(false);
          setClose(true);
        } catch (error) {
          setLoading(false);
        }
      } else if (e.target.value?.length === 0) {
        setClose(false);
      }
    };
    const handleInputEmpty = () => {
      setSearchValue("");
    };
    const handleSearchClose = () => {
      setClose(!close);
      setSearchValue("");
    };

    const setPostOnScroll = async (page) => {
      if (page > 1) {
        setSpinnerLoading(true);
        const res = await postSearch(searchValue, page);
        setSpinnerLoading(false);
        setPostValue((prev) => [...prev, ...res?.data]);
      }
    };

    return (
      <div className="wrapper">
        <AppNavbar
          handleSearch={handleSearch}
          loading={loading}
          value={searchValue}
          handleInputEmpty={handleInputEmpty}
        />
        {!error ? (
          <div className="main_body">
            <Container>
              <div className="feed_row">
                <div
                  className={`feed_col_left sticky-top ${
                    !location.pathname.includes("feed") ? "is_shadow" : ""
                  }`}
                >
                  {location.pathname.includes("feed") && (
                    <>
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <ProfileCard />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <SuggestionListOther
                              popularLength="2"
                              peopleYouMayKnowLength="2"
                            />
                          </div>
                        </Suspense>
                      </div>
                      <LeftFooter />
                    </>
                  )}
                  {!location.pathname.includes("feed") && <VerticalNav />}
                </div>
                <div className="feed_col_middle">
                  {close && (
                    <>
                      {accountValue && (
                        <div className="Search-section feed-tab-wrapper">
                          <SearchMain
                            spinnerLoading={spinnerLoading}
                            AccountData={accountValue}
                            postValue={postValue}
                            setPostOnScroll={setPostOnScroll}
                            handleSearchClose={handleSearchClose}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <FeedProvider>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/feed" />
                      </Route>
                      <Route exact path="/feed">
                        <Feed AccountValue={accountValue} />
                      </Route>
                      <Route exact path="/feed/:postId">
                        <Feed />
                      </Route>
                      <Route exact path="/PreLogin">
                        <LoginModal />
                      </Route>
                      <Route exact path="/edit-privacy-settings">
                        <PrivacySettings />
                      </Route>
                      <Route exact path="/edit-help-support">
                        <HelpAndSupport />
                      </Route>
                      <Route exact path="/edit/:username">
                        <EditProfile />
                      </Route>
                      <Route exact path="/:username">
                        <Suspense fallback={<div>Loding...</div>}>
                          <Profile />
                        </Suspense>
                      </Route>
                    </Switch>
                  </FeedProvider>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <BrokenPage
            heading_1="Sorry, this page isn’t available"
            heading_2="This link you followed may be broken, or the page may have been removed."
          />
        )}
      </div>
    );
  };

  return (
    <>
      <FeedRender />
    </>
  );
};

export default PrivateLayout;
