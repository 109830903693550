import Slide from "react-reveal/Slide";

const CreatorItem = (props) => {
  const {
    creatorImg,
    card_content_bold,
    card_heading,
    card_para_end,
    card_content,
    card_para_start,
    card_heading_break,
    card_heading_break_last,
  } = props;
  return (
    <>
      <Slide bottom>
        <div className="creator-card-wrap">
          <div className="creator-content-wrap">
            <div className="creator-card-left">
              <figure>
                <img src={creatorImg} alt="creatorImage" />
              </figure>
            </div>
            <div className="creator-card-right">
              <div className="card-item-content">
                <h3>
                  {card_heading}
                  {card_heading_break && (
                    <>
                      <br />
                      {card_heading_break}
                    </>
                  )}
                  {card_heading_break_last && (
                    <>
                      <br /> {card_heading_break_last}
                    </>
                  )}
                </h3>
                <p>
                  {card_content}
                  {card_para_start}{" "}
                  {card_content_bold && <b>{card_content_bold}</b>}{" "}
                  {card_para_end}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default CreatorItem;
