import { Switch } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import LeftFooter from "../../components/shared/LeftFooter";
import "./index.scss";
import { updatePrivacySetting, getPrivacySetting } from "./Service";

export default function PrivacySettings() {
  const [switchState, setSwitchState] = useState({});

  const privacySettingData = async () => {
    const res = await getPrivacySetting();
    setSwitchState(res);
  };

  useEffect(() => {
    privacySettingData();
  }, []);

  const handleOnChange = async (event) => {
    switchState[event] = !switchState[event];
    setSwitchState({ ...switchState });
    await updatePrivacySetting(switchState);
  };

  return (
    <>
      <div className="privacy_setting_wrap">
        <div className="title on_web">Privacy Settings</div>
        <h1 className="title on_mobile">Privacy</h1>

        <div className="body">
          <h5 className="title on_mobile">Account Privacy</h5>
          <ul>
            <li>
              <div className="w-100">
                <div className="privacy_li_it">
                  Private Account
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("privateAccount")}
                    isChecked={switchState.privateAccount}
                  />
                </div>
                <p>
                  You will have to manually review each follower if turned on.
                </p>
              </div>
            </li>
            <li>
              <div className="w-100">
                <h5 className="privacy_li_it">
                  Find me via Phone Number{" "}
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("findMeViaPhone")}
                    isChecked={switchState.findMeViaPhone}
                  />
                </h5>
                <p>
                  Phone contacts Signing up won’t be able to Find or Message you
                  after Turning OFF.
                </p>
              </div>
            </li>
          </ul>
          <div className="inner_wrap">
            <div className="sub_title">Who can Tag me in Posts</div>
            <ul>
              <li>
                <h5 className="privacy_li_it">
                  Public{" "}
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("publicCanTag")}
                    isChecked={switchState.publicCanTag}
                  />
                </h5>
              </li>
              <li>
                <h5 className="privacy_li_it">
                  Followers{" "}
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("followersCanTag")}
                    isChecked={switchState.followersCanTag}
                  />
                </h5>
              </li>
              <li>
                <h5 className="privacy_li_it">
                  Following{" "}
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("followingCanTag")}
                    isChecked={switchState.followingCanTag}
                  />
                </h5>
              </li>
            </ul>
          </div>
          <div className="inner_wrap mt-62">
            <h5 className="sub_title">Who can comment on my Post / Stories</h5>
            <ul>
              <li>
                <h5 className="privacy_li_it">
                  Public{" "}
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("publicCanComment")}
                    isChecked={switchState.publicCanComment}
                  />
                </h5>
              </li>
              <li>
                <h5 className="privacy_li_it">
                  Followers{" "}
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("followersCanComment")}
                    isChecked={switchState.followersCanComment}
                  />
                </h5>
              </li>
              <li>
                <h5 className="privacy_li_it">
                  Following{" "}
                  <Switch
                    size="lg"
                    colorScheme="messenger"
                    onChange={(event) => handleOnChange("followingCanComment")}
                    isChecked={switchState.followingCanComment}
                  />
                </h5>
              </li>
            </ul>
          </div>
        </div>
        <div className="privacy-footer">
         <LeftFooter />
      </div>
      </div> 
    </>
  );
}
