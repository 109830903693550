import React, { useState, useRef } from "react";
import Deck from "./Deck";

import {
  makeStyles,
  //  withStyles,
  createStyles,
} from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// import IconButton from '@material-ui/core/IconButton';
// import SkipNextIcon from '@material-ui/icons/SkipNext';
// import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
// import ShuffleIcon from '@material-ui/icons/Shuffle';

// import Tooltip from '@material-ui/core/Tooltip';
// import Zoom from '@material-ui/core/Zoom';
import { useSprings } from "react-spring";
import { cards, Colors, from, texts, titles, to } from "./services";
// import { AppNavbar } from '../../components/shared/AppNavbar';
import PublicNavbar from "../../components/shared/PublicNavbar";
import { Footer } from "../../components/shared/Footer";

const useStyles = makeStyles((theme) =>
  createStyles({
    landingRoot: {
      position: "relative",
      height: "100vh",
      width: "100%",
      overflowX: "hidden",
      [theme.breakpoints.down("sm")]: {
        height: "109vh",
      },
    },
    item: {
      width: "",
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: "center",
      color: theme.palette.text.secondary,
      background: "transparent",
    },
    deckBox: {
      position: "relative",
      zIndex: "2",
      height: "100%",
    },
    iconGridContainer: {
      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        justify: "flex-start",
      },
      [theme.breakpoints.up("md")]: {
        flexDirection: "column",
        justify: "center",
        alignItems: "center",
      },
    },
    footer: {
      margin: "0px",
      position: "absolute",
      bottom: "0px",
      width: "100vw",
      "& a": {
        color: "rgb(255, 255, 255, 0.6)",
      },
      "& span": {
        color: "red",
      },

      padding: "1rem",
      fontSize: "16px",
      textAlign: "center",
      backgroundColor: "#3a3939",
      color: "rgb(255, 255, 255, 0.6)",
    },
  })
);

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 1)',
//     boxShadow: theme.shadows[2],
//     fontSize: '14px',
//     borderRadius: '25px',
//   },
// }))(Tooltip);

const MainFeature = () => {
  const { current: gone } = useRef(new Set());
  const topCardIndex = useRef(cards.length - 1);
  const [props, set] = useSprings(cards.length, (i) => ({
    ...to(i),

    from: from(i),
  }));

  // eslint-disable-next-line no-unused-vars
  const handleNext = () => {
    set((i) => {
      if (i !== topCardIndex.current || topCardIndex.current < 0) return;
      topCardIndex.current--;
      // topCardIndex.current = index;

      let x = (200 + window.innerWidth - 2 * 0) * 1;
      let rot = 2 + 1 * 10 * 0.2;
      let scale = 1.1;

      setTheme({ bgColor: Colors[i], text: texts[i], title: titles[i] });
      if (topCardIndex.current < 0)
        setTimeout(() => {
          gone.clear();
          set((i) => to(i));
          topCardIndex.current = cards.length - 1;
        }, 1500);

      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: {
          friction: 400,
          tension: 800,
        },
      };
    });
    // console.log("top current card number: " ,topCardIndex.current);
    // console.log("top current card length: " ,cards.length - 1);
  };

  // const handlePrev = () => {
  //   let x = (200 + window.innerWidth - 2 * 0) * -1;
  //    let rot = 2 + (1 * 10 * 0.2 );
  //    let scale = 1.1;
  //  set((i) => i === topCardIndex.current+1 ? to(topCardIndex.current + 1) : null)
  //     topCardIndex.current++
  //     return {
  //      x,
  //      rot,
  //      scale,
  //      delay: undefined,
  //      config: {
  //        friction: 400,
  //        tension: 800
  //      }
  //    }
  // }

  // const handleShuffle = () =>{
  //   console.log("handle Shuffle")
  //   // CardDetails[Math.floor(Math.random()*CardDetails.length)]
  // }

  // const [bgColor, setBgColor] = useState("yellow");
  const [theme, setTheme] = useState({
    bgColor: "#F9FBEF",
    text: "Breaking the Barrier World is now Open",
    title: "Cachy Select",
    bgCardColor: "#EDFFEE",
  });
  const classes = useStyles();

  React.useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (topCardIndex.current == 3 || 2 || 1) {
      setInterval(() => {
        handleNext();
      }, 4400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        id="landing_root"
        className={`${classes.landingRoot} main-feature-wrapper`}
        style={{ backgroundColor: theme.bgColor }}
      >
        <PublicNavbar />
        <br />
        <Box>
          <Grid
            className="deckbox"
            container
            direction="row-reverse"
            spacing={3}
            alignItems="center"
          >
            <Grid
              className="outerDeck"
              style={{ padding: "none", zIndex: "0" }}
              item
              xs={12}
              md={7}
            >
              <Box className={classes.deckBox}>
                <Deck
                  setTheme={setTheme}
                  theme={theme}
                  gone={gone}
                  set={set}
                  props={props}
                  topCardIndex={topCardIndex}
                />
              </Box>
            </Grid>

            <Grid className="text_feature" item xs={12} md={4}>
              <div className="feature" style={{ color: "#231F20" }}>
                <div
                  className="feature_title"
                  // style={{font:" normal normal bold 24px/66px Avenir Next"}}
                >
                  {theme.title}
                </div>
                <div
                  className="feature_text"
                  // style={{font: "normal normal 50px/80px Avenir Next",letterSpacing: "-1.52px",opacity: "1"}}
                >
                  {theme.text}
                </div>
              </div>
            </Grid>
            {/* 
          <Grid
              container
              item
              className={classes.iconGridContainer}
              xs={12}
              md={1}
              alignItems="center"
              spacing={2}
            >
             
            <Grid item>
              <LightTooltip
                  TransitionComponent={Zoom}
                  title="Previous Post"
                  placement="right"
                >
                  <IconButton aria-label="delete">
                    <SkipPreviousIcon onClick={handlePrev} style={{ color: 'black' }} />
                  </IconButton>
                </LightTooltip>
              </Grid>
              <Grid item>
                <LightTooltip
                  TransitionComponent={Zoom}
                  title="Next Post"
                  placement="right"
                >
                  <IconButton aria-label="next">
                    <SkipNextIcon onClick={handleNext} style={{ color: 'black' }} />
                  </IconButton>
                </LightTooltip>
              </Grid>
              <Grid item>
                <LightTooltip
                  TransitionComponent={Zoom}
                  title="Shuffle Posts"
                  placement="right"
                >
                  <IconButton aria-label="shuffle">
                    <ShuffleIcon onClick={handleShuffle}style={{ color: 'black' }} />
                  </IconButton>
                </LightTooltip>
              </Grid>
            </Grid> 
          */}
          </Grid>
        </Box>
      </div>
      <Footer isBottom extraItems backgroundColor={theme.bgColor} />
    </>
  );
};

export default MainFeature;
