import { Box, Button, Heading, Text, useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PublicNavbar from "../../components/shared/PublicNavbar";
import { Footer } from "../../components/shared/Footer";
import { Container } from "react-bootstrap";
import { MdWest } from "react-icons/md";
import { getJobDetailsById } from "./Service";
import { CustomModal } from "../../components/UI/Modal";
import ApplyForm from "./ApplyForm";

function JobDetails() {
  const { jobId } = useParams();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [jobData, setJobData] = useState({});

  const getJobDetailsByIdHalder = useCallback(async () => {
    const res = await getJobDetailsById(jobId);
    if (res.code === 200) {
      setJobData(res.data[0]);
    }
  }, [jobId]);
  useEffect(() => {
    getJobDetailsByIdHalder();
  }, [getJobDetailsByIdHalder]);
  return (
    <>
      <Box>
        <PublicNavbar />
        <div className="public_wrapper help jobs_wrapper job_details">
          <div className="body">
            <Container>
              <Box className="head_box">
                <Heading textAlign="center" className="heading">
                  {jobData?.job_name}
                </Heading>
              </Box>
              <Box className="desc_body_box">
                <Text
                  className="back_text"
                  onClick={() => history.push(`/jobs`)}
                >
                  <MdWest /> Back to Jobs
                </Text>
                <Box className="details_box">
                  <Text className="title_text">{jobData?.department}</Text>
                  <Text className="text_desc">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${jobData?.job_description}`,
                      }}
                    />
                  </Text>
                  <Box className="apply_box">
                    {/* <Text className="title_text">
                      you should apply if you:{" "}
                    </Text>
                    <Text className="text_desc">
                      {" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ultricies mi eget mauris pharetra. Amet risus
                      nullam eget felis eget nunc lobortis. Purus in massa
                      tempor nec. Porta nibh venenatis cras sed. Viverra ipsum
                      nunc aliquet bibendum enim. Risus pretium quam vulputate
                      dignissim suspendisse in.
                    </Text> */}
                    <Box
                      display="flex"
                      justifyContent="center"
                      className="button_box"
                    >
                      <Button variant="solid" onClick={onOpen}>
                        Apply Now
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Container>
          </div>
        </div>
        <Footer extraItems />
      </Box>
      <CustomModal
        show={isOpen}
        handleClose={onClose}
        className="modal_sm"
        title="Apply For Job"
      >
        <ApplyForm jobId={jobId} onClose={onClose} />
      </CustomModal>
    </>
  );
}

export default JobDetails;
