import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";

const BrokenPage = ({ heading_1, heading_2 }) => {
  return (
    <>
      <div className="broken_page">
        <div className="broken_wraper">
          <p className="p_1">{heading_1}</p>
          <p className="p_2">{heading_2}</p>
          <div className="download_image">
            <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
              <img
                src={PlayStore}
                width="123.15px"
                height="36.28px"
                alt="app store"
              />
            </a>
            <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
              <img
                width="123.15px"
                height="36.28px"
                src={AppStore}
                alt="app store"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrokenPage;
