import { CustomModal } from "../../components/UI/Modal";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";
import bigLock from "../../assets/img/lock.svg";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";
import PublicNavbar from "../../components/shared/PublicNavbar";

const SharePostLogin = () => {
  const loginHandler = () => {
    window.location.href = "/";
  };

  return (
    <>
      <PublicNavbar />
      <CustomModal
        className="share_post_login"
        crossIcon={false}
        show={true}
        handleClose={() => {
          loginHandler();
        }}
      >
        <i
          className="bi bi-x"
          style={{
            fontSize: "30px",
            position: "absolute",
            top: "5px",
            right: "10px",
          }}
          onClick={loginHandler}
        ></i>
        <div className="share_post_wraper">
          <img
            style={{ margin: "0px auto", height: 60 }}
            src={bigLock}
            alt="lock icon"
          ></img>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            This content is private.{" "}
          </p>
          <p style={{ fontSize: "14px" }}>Viewed it on Cachy Mobile</p>
          <div className="download_image">
            <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
              <img
                src={PlayStore}
                width="123.15px"
                height="36.28px"
                alt="app store"
              />
            </a>
            <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
              <img
                width="123.15px"
                height="36.28px"
                src={AppStore}
                alt="app store"
              />
            </a>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default SharePostLogin;
