import React from "react";
import { SkeletonText } from "@chakra-ui/react";

function JobFilterSkeleton(props) {
  const { lines } = props;
  return (
    <>
      <SkeletonText noOfLines={lines} spacing="4" height="60px" width="50%" />
    </>
  );
}

export default JobFilterSkeleton;
