export const TYPE = {
  fcm: "fcm",
  sms: "sms",
};

const dev = process.env.REACT_APP_API_URL.includes("dev");

export const FEED_IMG_URL = "https://cdn-prod.cachy.com/";

export const PROFILE_IMG_URL = `https://cdn-${
  dev ? "dev" : "prod"
}-us.cachy.com/`;

export const APP_STORE_LINK =
  "https://apps.apple.com/in/app/cachy-social/id6443464123";
export const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.cachysocial";

export const BASE_URL_2 = `https://social${dev ? "-dev" : ""}.cachy.com`;
export const BASE_URL_3 = `https://userserver${dev ? "-dev" : ""}.cachy.com`;
export const BASE_URL_4 = `https://admin${dev ? "-dev" : ""}.cachy.com`;
export const COPY_BASE_URL = dev
  ? "https://dev-mobile.cachy.com/"
  : "https://cachy.com/";

export const SOCKET_SERVER_URL = "https://websocket.cachy.com";
