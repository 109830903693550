import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { MdChevronRight } from "react-icons/md";

function JobListCard(props) {
  const { data } = props;
  return (
    <>
      <Box className="job_card">
        <Box>
          <Text className="job_title">{data?.job_name}</Text>
          <Text className="job_location">{data?.job_location}</Text>
          <Text className="job_type">{data?.job_type}</Text>
        </Box>
        <MdChevronRight />
      </Box>
    </>
  );
}

export default JobListCard;
