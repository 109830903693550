import { useState } from "react";
import {
  Avatar,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import { useHistory } from "react-router-dom";
import { COPY_BASE_URL, FEED_IMG_URL } from "../../utils/config";
import ReportModalPage from "../../pages/ReportModal/ReportModal";
import { useUser } from "../../context/UserProvider";

const StoryStatusItem = (props) => {
  const { handleModalStatus, Vditem } = props;
  const { userData } = useUser();
  const isOwnPostForReport = userData?.uuid === Vditem?.uuid;
  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();
  const toast = useToast();
  const id = "test-toast";
  let postIdUrl = "";
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <>
      <div className="player-wrapper" style={{ paddingTop: 0 }}>
        <div className="upper_shadow" />
        <div className="avtar_username_container">
          <Avatar
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/${Vditem?.userName || Vditem.username}`)
            }
            size="sm"
            name={`${Vditem?.firstName} ${Vditem?.lastName}`}
          />
          <span
            className="username"
            onClick={() =>
              history.push(`/${Vditem?.userName || Vditem?.username}`)
            }
          >
            @{Vditem?.userName || Vditem?.username}
          </span>
        </div>

        <div className="three_dot_icon_container">
          <i
            className="icon-md threedot_icon_story"
            onClick={
              isOwnPostForReport
                ? () => setDeleteModal(!deleteModal)
                : handleModalStatus
            }
          ></i>
        </div>

        <i
          className="icon share_story"
          style={{
            position: "absolute",
            bottom: "144px",
            right: "15px",
            width: "26px",
            height: "26px",
            zIndex: "1",
          }}
        ></i>
        <li className="share_icon">
          <Popover
            isLazy
            className="popover_share"
            placement="top"
            arrowSize="15"
            boundary="none"
          >
            <PopoverTrigger>
              <i
                className="icon share_story"
                style={{
                  position: "absolute",
                  bottom: "144px",
                  right: "15px",
                  width: "26px",
                  height: "26px",
                  zIndex: "1",
                }}
              ></i>
            </PopoverTrigger>
            <PopoverContent
              className="popover_content"
              style={{ width: "100%", borderRadius: "17px" }}
            >
              <PopoverArrow style={{ borderBottomRightRadius: "4px" }} />
              <div className="icon_list" style={{ border: "none" }}>
                <FacebookShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${Vditem.postId || postIdUrl}`}
                >
                  <i className="fab fa-facebook-f" title="facebook"></i>
                </FacebookShareButton>
                <TwitterShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${Vditem.postId || postIdUrl}`}
                >
                  <i className="fab fa-twitter" title="twitter"></i>
                </TwitterShareButton>
                <LinkedinShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${Vditem.postId || postIdUrl}`}
                >
                  <i className="fab fa-linkedin" title="linkedin"></i>
                </LinkedinShareButton>
                <EmailShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${Vditem.postId || postIdUrl}`}
                >
                  {" "}
                  <i className="far fa-envelope" title="email"></i>
                </EmailShareButton>
                <button>
                  <i
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${COPY_BASE_URL}feed/${Vditem.postId || postIdUrl}`
                      );
                      if (!toast.isActive(id)) {
                        toast({
                          id,
                          position: "top",
                          title: "Copied",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                      }
                    }}
                    className="fas fa-link"
                    title="copy link"
                  ></i>
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </li>
        {!isSafari ? (
          <video
            className="story_player"
            style={{ height: "100%", borderRadius: "12px", width: "100%" }}
            src={`${FEED_IMG_URL}${Vditem.video.remoteid}`}
            type="video/mp4"
            controls="hidden"
            playsInline
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            poster={`${FEED_IMG_URL}${Vditem.coverImg?.remoteid}`}
            // autoPlay={autoPlayStatus}
          ></video>
        ) : (
          <video
            className="story_player"
            style={{ height: "100%", borderRadius: "12px", width: "100%" }}
            controls="hidden"
            playsInline
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            poster={`${FEED_IMG_URL}${Vditem.coverImg?.remoteid}`}
            // autoPlay={autoPlayStatus}
          >
            <source
              src={`${FEED_IMG_URL}${Vditem.video.remoteid}`}
              type="video/mp4"
            />
          </video>
        )}

        <div className="bottom_shadow" />
      </div>
      {isOwnPostForReport ? (
        <ReportModalPage
          handleModalStatus={handleModalStatus}
          fromSelfPost
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
          isOwnPostForReport={isOwnPostForReport}
          postId={Vditem?.postId ? Vditem?.postId : Vditem?.stories[0]?.postId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default StoryStatusItem;
