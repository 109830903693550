import React, { useState, useEffect } from "react";

import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../components/UI/Modal";

const DeleteAccount = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const location = useLocation();
  const isDeleteAccount = location.pathname === "/delete-account";

  const handleClose = () => {
    setShowModal(false);
    history.push("/login");
  };

  useEffect(() => {
    const userDataStr = localStorage.getItem("userData");
    if (userDataStr) {
      setUserData(JSON.parse(userDataStr));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("auth_secret");
    if (token && userData) {

      if (userData?.profile?.username === e.target.username.value) {

        try {
          const url = `${process.env.REACT_APP_API_URL}/cachy/v1/accounts/me`;
          const response = await axios.delete(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_secret")}`,
            },
          });
          if (response.status === 204) {
            localStorage.clear();
            alert("Account deleted successfully");
            history.push("/login");
          } else {
            setError(response.data.message || "Failed to delete account");
          }
        } catch (error) {
          setError(
            error.response?.data?.message ||
            "An error occurred while trying to delete the account"
          );
        }
      } else {
        setError("Invalid username !");
      }
    } else {
      setShowModal(true); // Show modal
    }
  };

  return (
    <div
      className="modal_container"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
      }}
    >
      <p style={{ textAlign: "center", fontSize: "2rem", fontWeight: 600 }}>
        {isDeleteAccount ? "Delete Account" : "Delete All Data"}
      </p>
      <div
        style={{
          textAlign: "center",
          marginTop: "1rem",
          border: "1px solid grey",
          borderRadius: "0.7rem",
          padding: "1.5rem",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 text-start">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" name="username" required />
          </Form.Group>
          <Form.Group className="mb-3 text-start">
            <Form.Label>Phone No</Form.Label>
            <Form.Control type="text" name="phone" required />
          </Form.Group>
          {error && <p className="text-danger text-center mb-2">{error}</p>}

          <Button
            type="submit"
            style={{
              width: "278px",
              height: "35.94px",
              borderRadius: "278px",
              fontSize: "14px",
              backgroundColor: "#D8463D",
            }}
          >
            Submit
          </Button>
        </Form>
      </div>

      <CustomModal
        className="logoutButton"
        show={showModal}
        handleClose={handleClose}
        title="Not Logged In!"
      >
        You are not logged in. Please log in to continue.

        <p style={{ display: "flex", justifyContent: "end" }}>
          <Button
            style={{
              background: "white",
              color: "Red",
              right: "0",
            }}
            onClick={handleClose}
          >
            Go to Login
          </Button>
        </p>
      </CustomModal>
    </div>
  );
};

export default DeleteAccount;
