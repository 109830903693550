import React from "react";
import { Stack, Skeleton, SkeletonText, Box } from "@chakra-ui/react";

function PublicPageSkeleton() {
  return (
    <>
      <Stack>
        <Box display="flex" justifyContent="center" mb="5">
          <Skeleton height="10px" width="40%" />
        </Box>

        <SkeletonText mt="4" noOfLines={20} spacing="4" />
      </Stack>
    </>
  );
}

export default PublicPageSkeleton;
