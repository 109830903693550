import { useEffect, useState } from "react";

import { Button } from "react-bootstrap";

import { followUser, getPopularAndPeopleYouMayKnow } from "../layout/Service";
import { useUser } from "../../context/UserProvider";
import UserListSkeleton from "./UserListSkeleton";
import { CustomModal } from "../UI/Modal";
import Suggestions from "../../pages/Feed/Suggestions";
import SuggestionUserList from "./SuggestionUserList";
import { unfollowUser } from "../../pages/Following/Service";

/**
 * @author
 * @function SuggestionListOther
 **/

const SuggestionListOther = () => {
  const { setUserProfileData, userProfileData } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isListPopularEmpty, setIsListPopularEmpty] = useState(false);

  const [lengthList, setLengthList] = useState(2);

  const [peopleYouMayKnowList, setPeopleYouMayKnowList] = useState([]);
  const [poplarList, setPoplarList] = useState([]);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [showMore, setShowMore] = useState("Show more");

  const handleCloseSuggestionModal = () => setShowSuggestionModal(false);

  const getPopularAndPeopleYouMayKnowHanlder = async () => {
    setIsLoading(true);
    const res = await getPopularAndPeopleYouMayKnow();
    res?.youMayKnow?.forEach((el) => {
      el.isFollowing = false;
    });
    setPoplarList(res?.popularUsers);
    setPeopleYouMayKnowList(res?.youMayKnow);
    setIsLoading(false);
  };

  useEffect(() => {
    getPopularAndPeopleYouMayKnowHanlder();
    return () => {
      setPoplarList([]);
      setPeopleYouMayKnowList([]);
      setIsLoading(false);
    };
  }, []);

  const followUserHandler = async (id, privateAccount) => {
    if (privateAccount) {
      setPeopleYouMayKnowList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 2,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
      setPoplarList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 2,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
    } else {
      setUserProfileData((prev) => ({
        ...prev,
        following: parseInt(userProfileData.following) + 1,
      }));
      setPeopleYouMayKnowList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 1,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
      setPoplarList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 1,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
    }

    const payload = {
      followingUuids: [id],
    };
    await followUser(payload);
  };

  const unfollowUserHandler = async (id) => {
    setUserProfileData((prev) => ({
      ...prev,
      following: parseInt(userProfileData.following) - 1,
    }));
    setPeopleYouMayKnowList((prev) =>
      prev.map((value) => {
        if (value.uuid == id) {
          return {
            ...value,
            isFollow: 0,
          };
        } else {
          return {
            ...value,
          };
        }
      })
    );
    setPoplarList((prev) =>
      prev.map((value) => {
        if (value.uuid == id) {
          return {
            ...value,
            isFollow: 0,
          };
        } else {
          return {
            ...value,
          };
        }
      })
    );
    const payload = {
      unfollowUuids: [id],
    };
    await unfollowUser(payload);
  };

  const handleShowMore = () => {
    setShowMore(showMore === "Show more" ? "Show less" : "Show more");
    setLengthList(
      lengthList === 2
        ? peopleYouMayKnowList.length > poplarList.length
          ? peopleYouMayKnowList.length
          : poplarList.length
        : 2
    );
  };

  return (
    <>
      <div className="people_you_may_know_card">
        {!isListPopularEmpty && (
          <>
            {poplarList?.length || isLoading ? (
              <>
                <h5 style={{ marginTop: "0px", color: "#D8463D" }}>Popular</h5>
                {isLoading && (
                  <>
                    {" "}
                    <UserListSkeleton />
                    <UserListSkeleton />
                    <UserListSkeleton />
                  </>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ul>
                    {poplarList?.slice(0, lengthList)?.map((person, index) => (
                      <SuggestionUserList
                        key={index}
                        person={person}
                        followUserHandler={followUserHandler}
                        unfollowUserHandler={unfollowUserHandler}
                      />
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
            <>
              {peopleYouMayKnowList?.length || isLoading ? (
                <>
                  <h5 style={{ marginTop: "15px", color: "#D8463D" }}>
                    People you may know
                  </h5>
                  {isLoading && (
                    <>
                      {" "}
                      <UserListSkeleton />
                      <UserListSkeleton />
                      <UserListSkeleton />
                    </>
                  )}

                  <ul>
                    {peopleYouMayKnowList
                      ?.slice(0, lengthList)
                      ?.map((person, index) => (
                        <SuggestionUserList
                          key={index}
                          person={person}
                          followUserHandler={followUserHandler}
                          unfollowUserHandler={unfollowUserHandler}
                        />
                      ))}
                  </ul>
                </>
              ) : (
                ""
              )}
            </>
          </>
        )}
        <CustomModal
          show={showSuggestionModal}
          handleClose={handleCloseSuggestionModal}
          className="user_list_modal"
          title="Suggestions"
          modalBodyStyle={{ padding: "0rem 1rem" }}
        >
          <Suggestions
            popularList={poplarList}
            peopleYouMayKnowList={peopleYouMayKnowList}
            followUserHandler={followUserHandler}
            unfollowUserHandler={unfollowUserHandler}
          />
        </CustomModal>
        {(poplarList?.length > 2 || peopleYouMayKnowList?.length > 2) && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button onClick={handleShowMore} className="button_show_more">
              {showMore}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SuggestionListOther;
