
import React, { Fragment, useEffect, useState } from "react";
import MobileTerm from "../Help/mobileFaq";

const TermsItem = (props) => {
    const { data, heading } = props.privacydataItem;
    const PrivacyData = data;
    const privacyHeading = heading;
    const [article, setArticle] = useState([])
    useEffect(() => {
        setArticle(data[1]?.articles_list)
    }, [])

    return (
        <>
            <div className="container-wrap">
                <div className="privacy-wrapper privacy-grid">
                    <aside>
                        <ul>
                            {PrivacyData?.map((item, index) => {
                                const { nav_list } = item;
                                return (
                                    <Fragment key={index}>
                                        {nav_list?.map((subItem, idx) => (
                                            <>
                                                <li key={idx}><a href={`#${subItem.target_name}`} >{subItem.nav_item}</a></li>
                                            </>
                                        ))}
                                    </Fragment>
                                )
                            })}
                        </ul>
                    </aside>
                    <div className="privacy-content-wrapper">
                        <div className="privacy-heading">
                            <h2>{privacyHeading?.title}</h2>
                            <h5>{privacyHeading?.question}</h5>
                            {privacyHeading?.sub_question_terms ?
                                <h4>{privacyHeading?.sub_question_terms}</h4>
                                : ""}
                            <p>{privacyHeading?.answer}</p>
                            {privacyHeading?.sub_question ?
                                <h5>{privacyHeading?.sub_question}</h5> : ""}
                        </div>
                        <article className="article-wrapper desktop-privacy">
                            {article?.map((articleItem, index) => {
                                const { title, target_name, answer, id } = articleItem;
                                return (
                                    <div className="article-item" key={index} id={`${target_name}`}>
                                        <div className="article-heading">
                                            <h3>{`${id}. ${"  "} ${title}`}</h3>
                                        </div>
                                        <div className="article-answer terms-ans">
                                            {answer?.map((list, index) => {
                                                const { heading, answer_item, sub_answer_item, ans_id, sub_answer_bold, answer_item_child,
                                                    anchor_bold, order_list, order_name, internal_bold, answer_semi_child } = list
                                                return (
                                                    <div key={index} className={ans_id && "terms-items"}>
                                                        {heading || ans_id ? <span className="article-ans-heading"> {ans_id && `${ans_id}.`} {heading}</span> : ""}
                                                        {answer_item && <span className="article-ans-content">{answer_item}
                                                            {sub_answer_item &&
                                                                <>
                                                                    <br />
                                                                    <b className="article-sub-ans">
                                                                        {sub_answer_item}
                                                                    </b>
                                                                </>}
                                                            {sub_answer_bold && <b><>&nbsp;</>{sub_answer_bold}</b>}
                                                        </span>}
                                                        {anchor_bold && <a href={`mailto:${anchor_bold}`} className="sub-bold italic">{anchor_bold}</a>}
                                                        {answer_semi_child && <span className="article-ans-content">{answer_semi_child}</span>}
                                                        {internal_bold && <span className="sub-bold">{internal_bold}</span>}
                                                        {answer_item_child && <span className="article-ans-content">{answer_item_child}</span>}
                                                        <ul className={order_name ? `${order_name} order-list` : "order-list"} > {order_list?.map((item, idx) => (
                                                            <>
                                                                <li key={idx}>
                                                                    {item.order_anchor && <a href={`${item.order_anchor}`} target="_blank">{item.order_anchor}</a>}
                                                                    {item.order_heading_content}
                                                                    {item.order_heading && <span className="sub-bold">{item.order_heading}</span>}{" "}
                                                                    {item.order_item}
                                                                    {item?.order_internal_bold && <span className="sub-bold">{item.order_internal_bold}</span>}
                                                                    {item?.order_sub_item}
                                                                    {item.sub_order_item &&
                                                                        <ol className="roman-list-wrap">
                                                                            {item.sub_order_item?.first && <li>{item.sub_order_item?.first}</li>}
                                                                            {item.sub_order_item?.two && <li>{item.sub_order_item?.two}</li>}
                                                                            {item.sub_order_item?.three && <li>{item.sub_order_item?.three}</li>}
                                                                            {item.sub_order_item?.fourth && <li>{item.sub_order_item?.fourth}</li>}
                                                                            {item.sub_order_item?.five && <li>{item.sub_order_item?.five}</li>}
                                                                        </ol>
                                                                    }
                                                                </li>
                                                            </>
                                                        ))} </ul>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </article>
                        <div className="mobile-privacy-wrapper">
                            <MobileTerm article={article}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsItem