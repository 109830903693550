import PublicNavbar from "../../components/shared/PublicNavbar";
import { Footer } from "../../components/shared/Footer";
import { Container } from "react-bootstrap";
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import Android from "../../assets/img/icons/android_icon.svg";
import Ios from "../../assets/img/icons/ios_icon.svg";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";

const DownloadApp = () => {
  return (
    <div className="main_download parent-beta-wrapper">
      <PublicNavbar />
      <div
        className="public_wrapper help jobs_wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Container className="download_container beta-page-wrapper">
            <Box className="download_box">
              <p className="beta_cong_text">
                Find, Connect and Socialise-via Conversations!
              </p>
              <iframe
                className="trailer_video"
                src="https://www.youtube.com/embed/zhl_KjBzD1I"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="allowfullscreen"
              ></iframe>
              <div className="social-wrapper">
                <p style={{ fontSize: "15px" }}>Share</p>
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "70%",
                  }}
                  className="socail-unorder"
                >
                  <li>
                    <FacebookShareButton
                      url="https://www.youtube.com/embed/zhl_KjBzD1I"
                      style={{ height: "40px", width: "40px" }}
                      className="icon_sm facebook_md"
                    />
                    {/* <i className="icon-md facebook_md"></i> */}
                  </li>
                  <li>
                    <TwitterShareButton
                      url="https://www.youtube.com/embed/zhl_KjBzD1I"
                      style={{ height: "40px", width: "40px" }}
                      className="icon_sm twitter_md"
                    />
                  </li>
                  <li>
                    <EmailShareButton
                      url="https://www.youtube.com/embed/zhl_KjBzD1I"
                      style={{ height: "40px", width: "40px" }}
                      className="icon_sm email_md"
                    />
                  </li>
                  <li>
                    <LinkedinShareButton
                      url="https://www.youtube.com/embed/zhl_KjBzD1I"
                      style={{ height: "40px", width: "40px" }}
                      className="icon_sm linkedin_md"
                    />
                  </li>
                  <li>
                    <WhatsappShareButton
                      url="https://www.youtube.com/embed/zhl_KjBzD1I"
                      style={{ height: "40px", width: "40px" }}
                      className="icon_sm whatsapp_md"
                    />
                  </li>
                  <li>
                    <Popover placement="top" style={{ width: "20px" }}>
                      <PopoverTrigger>
                        <i
                          onClick={() =>
                            navigator.clipboard.writeText(
                              "https://www.youtube.com/embed/zhl_KjBzD1I"
                            )
                          }
                          style={{ height: "40px", width: "40px" }}
                          className="icon_sm copylink_md"
                        ></i>
                      </PopoverTrigger>
                      <PopoverContent
                        style={{
                          width: "90px",
                          boxShadow: "none",
                        }}
                      >
                        <PopoverArrow />
                        <PopoverBody>copied !</PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </li>
                </ul>
              </div>
              <div className="beta_buttons">
                <div>
                  <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
                    <img src={Android} alt="Download Android App" />
                  </a>
                  {/* <a  href={`${manuallyDownload}`} target="_blank" rel="noreferrer"><img src={manuallyDownloadIcon} alt="Download manually"></img> </a> */}
                </div>
                <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                  <img src={Ios} alt="Download iOS App" />
                </a>
              </div>
            </Box>
          </Container>
        </div>
      </div>
      <Footer extraItems />
    </div>
  );
};

export default DownloadApp;
