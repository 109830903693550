import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

function ModalComponent(props) {
  const { isOpen, onClose, title, className } = props;
  return (
    <>
    <div >
      <Modal isOpen={isOpen} onClose={onClose} className={className} isCentered >
        <ModalOverlay />
        <ModalContent style={{width:"90%"}}>
          {title && <ModalHeader>{title}</ModalHeader>}

          <ModalCloseButton />
          <ModalBody >{props.children}</ModalBody>
        </ModalContent>
      </Modal>
      </div>
    </>
  );
}

export default ModalComponent;
