import { Avatar } from "@chakra-ui/avatar";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { toast } from "@chakra-ui/toast";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { CustomModal } from "../../../components/UI/Modal";
import { useUser } from "../../../context/UserProvider";
import { timeGenerator } from "../../../utils";
import { COPY_BASE_URL } from "../../../utils/config";
import ContributorsDetailsInner from "../../Feed/ContributorsDetailsInner";
import ReportModalPage from "../../ReportModal/ReportModal";
import CustomInput from "../CustomInput";

const PostModal = (props) => {
  const {
    postModal,
    handleClose,
    playerData,
    videoUrl,
    videoPoster,
    commentsOnPost,
    handleFeedLike,
    handleCommentLike,
    handleReplyLike,
    getCommentsByPostHandler,
    reportAbuseData,
  } = props;
  const ref = useRef(null);
  const history = useHistory();
  const id = "test-toast";

  const [comment, setComment] = useState("");
  const [isReplying, setIsReplying] = useState(false);
  const [commentForReply, setCommentForReply] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { userProfileData, userData } = useUser();
  const isOwnPostForReport = userData?.uuid == playerData?.uuid;

  const handleReply = (comment) => {
    setComment(`@${comment?.un} `);
    setIsReplying(true);
    ref?.current?.focus();
    setCommentForReply(comment);
  };
  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  return (
    <>
      <CustomModal
        show={postModal}
        handleClose={handleClose}
        className={"player_modal post-modal-wrapper"}
        crossIcon={true}
        fromPlayer
        // storyStatusClass="story-status-backdrop"
      >
        <div className="player_row">
          <div className="player_col left">
            <video
              className={` react-player`}
              src={videoUrl}
              type="video/mp4"
              poster={videoPoster}
              controls
              controlsList="nodownload noplaybackrate"
              disablePictureInPicture
              autoPlay
            ></video>
          </div>
          <div className="player_col right">
            <div className="head">
              <div className="media">
                {/* <img src={DP} alt="" /> */}
                <Avatar
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/${playerData?.userName}`)}
                  size="lg"
                  name={`${playerData?.firstName} ${playerData?.lastName}`}
                />
                <div className="media_details">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h5
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push(`/${playerData?.userName}`)}
                      >
                        {playerData.firstName} {playerData.lastName}
                      </h5>

                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(`/${playerData?.userNames}`)
                        }
                      >
                        @{playerData?.userName}
                      </p>
                    </div>
                    <div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="icon threedot_icon"
                        onClick={() =>
                          isOwnPostForReport
                            ? setDeleteModal(true)
                            : setModalStatus(!modalStatus)
                        }
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="body">
              <p className="metadata">{playerData?.metadata}</p>
              <p className="text-conti">Contributors</p>
              <ul>
                {playerData?.contributorsDetails?.map((c, index) => (
                  <ContributorsDetailsInner key={index} contributor={c} />
                ))}
              </ul>
            </div>

            <div className="feed_bottom">
              <ul style={{ display: "flex", alignItems: "flex-start" }}>
                <li onClick={() => handleFeedLike(playerData)}>
                  <p>
                    <i
                      className={`icon ${
                        playerData.isLiked ? "liked heart_anim" : "like"
                      }`}
                    ></i>
                  </p>
                </li>
                <li>
                  <i
                    onClick={() => ref?.current?.focus()}
                    className="icon comment"
                  ></i>
                </li>

                <li className="share_icon">
                  <Popover
                    isLazy
                    className="popover_share"
                    placement="top"
                    arrowSize="15"
                    boundary="none"
                  >
                    <PopoverTrigger>
                      <i className="icon share"></i>
                    </PopoverTrigger>
                    <PopoverContent
                      className="popover_content"
                      style={{ width: "100%", borderRadius: "17px" }}
                    >
                      <PopoverArrow
                        style={{ borderBottomRightRadius: "4px" }}
                      />
                      <div className="icon_list" style={{ border: "none" }}>
                        <FacebookShareButton
                          style={{ height: "16px" }}
                          url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                        >
                          <i className="fab fa-facebook-f" title="facebook"></i>
                        </FacebookShareButton>
                        <TwitterShareButton
                          style={{ height: "16px" }}
                          url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                        >
                          <i className="fab fa-twitter" title="twitter"></i>
                        </TwitterShareButton>
                        <LinkedinShareButton
                          style={{ height: "16px" }}
                          url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                        >
                          <i className="fab fa-linkedin" title="linkedin"></i>
                        </LinkedinShareButton>
                        <EmailShareButton
                          style={{ height: "16px" }}
                          url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                        >
                          {" "}
                          <i className="far fa-envelope" title="email"></i>
                        </EmailShareButton>
                        <button>
                          <i
                            // onClick={() => handleCopy(playerData)}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${COPY_BASE_URL}feed/${playerData.postId}`
                              );
                              if (!toast.isActive(id)) {
                                toast({
                                  id,
                                  position: "top",
                                  title: "Copied",
                                  status: "success",
                                  duration: 3000,
                                  isClosable: true,
                                });
                              }
                            }}
                            className="fas fa-link"
                            title="copy link"
                          ></i>
                        </button>
                      </div>
                    </PopoverContent>
                  </Popover>
                </li>
              </ul>
              {/* {playerData.isLiked && playerData.isLiked ? (
                <>
                  <p className="like_comment_details  like-details">
                    Liked by
                    <strong>
                      {playerData.isLiked ? " You " : ""}
                      {playerData?.likesCount > 0 && (
                        <>
                          and {playerData?.likesCount}{" "}
                          {playerData?.likesCount > 1 ? "others" : "other"}
                        </>
                      )}
                    </strong>
                  </p>
                </>
              ) : (
                <>
                  {playerData?.likesCount > 0 && (
                    <>
                      <p className="like-content">
                        {
                          // eslint-disable-next-line eqeqeq
                          playerData.likesCount == 1
                            ? `${playerData.likesCount} Like`
                            : `${playerData.likesCount} Likes`
                        }
                      </p>
                    </>
                  )}
                </>
              )} */}

              <div className="comment_body" style={{ width: "100%" }}>
                <ul className="comment_ul">
                  <div className="child-comment-wrap">
                    {commentsOnPost.map((comment, index) => (
                      <div key={index}>
                        <li
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Avatar
                            size="sm"
                            onClick={() => history.push(`/${comment.un}`)}
                            style={{ marginTop: "5px" }}
                            name={`${comment.fn} ${comment.ln}`}
                            mr="2"
                            mt="1"
                          />

                          <div className="comment_details">
                            <div
                              onClick={() => history.push(`/${comment.un}`)}
                              className="full_name"
                            >{`${comment.fn} ${comment.ln}`}</div>
                            <p
                              onClick={() => history.push(`/${comment.un}`)}
                              className="username"
                            >
                              @{comment.un}
                            </p>
                            <div className="like_comment_icon">
                              <p
                                className="comment"
                                style={{ fontSize: "12px", width: "90%" }}
                                dangerouslySetInnerHTML={{
                                  __html: comment.attributedComment,
                                }}
                              ></p>
                              <i
                                className={`icon  ${
                                  comment?.isLiked ? "liked heart_anim" : "like"
                                } comment_like`}
                                style={{ height: "14px", width: "15px" }}
                                onClick={() => handleCommentLike(comment)}
                              />
                            </div>
                            <div className="ct_time_like_count">
                              <div> {timeGenerator(comment?.ct)}</div>
                              {
                                <div className="hide_vlaues">
                                  {comment.likesCount == 0
                                    ? ""
                                    : `${parseInt(comment?.likesCount)} likes`}
                                </div>
                              }

                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleReply(comment)}
                              >
                                Reply
                              </div>
                            </div>
                          </div>
                        </li>
                        {comment?.reply.map((cmtReply, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginLeft: "33px",
                            }}
                          >
                            <Avatar
                              size="xs"
                              // round={true}
                              onClick={() => history.push(`/${cmtReply.un}`)}
                              style={{ marginTop: "6px" }}
                              name={`${cmtReply?.fn} ${cmtReply?.ln}`}
                              mr="2"
                              mt="1"
                            />
                            <div className="comment_details">
                              <div
                                onClick={() => history.push(`/${cmtReply.un}`)}
                                className="full_name"
                                style={{ fontSize: "12px" }}
                              >{`${cmtReply?.fn} ${cmtReply?.ln}`}</div>
                              <p
                                onClick={() => history.push(`/${cmtReply.un}`)}
                                className="username"
                                style={{ fontSize: "12px" }}
                              >
                                @{cmtReply?.un}
                              </p>
                              <div className="like_comment_icon">
                                <p
                                  className="comment"
                                  style={{ fontSize: "10px", width: "88%" }}
                                >
                                  {cmtReply?.cmt}
                                </p>
                                <i
                                  className={`icon ${
                                    cmtReply.isLiked
                                      ? "liked heart_anim"
                                      : "like"
                                  } comment_like`}
                                  style={{ height: "14px", width: "15px" }}
                                  onClick={() =>
                                    handleReplyLike(comment, cmtReply)
                                  }
                                />
                              </div>
                              <div
                                className="ct_time_like_count"
                                style={{ fontSize: "10px" }}
                              >
                                <div> {timeGenerator(cmtReply?.ct)}</div>
                                {
                                  // eslint-disable-next-line eqeqeq
                                  <div className="hide_values">
                                    {cmtReply.likesCount == 0
                                      ? ""
                                      : `${parseInt(
                                          cmtReply?.likesCount
                                        )} likes`}
                                  </div>
                                }
                                <div
                                // onClick={() => handleReply(comment)}
                                >
                                  Reply
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </div>
                    ))}
                  </div>
                </ul>
              </div>

              <CustomInput
                comment={comment}
                setComment={setComment}
                userProfileData={userProfileData}
                setIsReplying={setIsReplying}
                setCommentForReply={setCommentForReply}
                commentForReply={commentForReply}
                commentsOnPost={commentsOnPost}
                getCommentsByPostHandler={getCommentsByPostHandler}
                refer={ref}
                isReplying={isReplying}
                playerData={playerData}
              />
            </div>
          </div>
        </div>
      </CustomModal>
      <ReportModalPage
        handleModalStatus={handleModalStatus}
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        isOwnPostForReport={isOwnPostForReport}
        fromFeed
        setModalStatus={setModalStatus}
        modalStatus={modalStatus}
        postId={playerData.postId}
        reportAbuseData={reportAbuseData}
      />
    </>
  );
};

export default PostModal;
