/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import { Link, useHistory } from "react-router-dom";

import parsePhoneNumber from "libphonenumber-js";

import ReCAPTCHA from "react-google-recaptcha";
import { Logo } from "./shared/Logo";
import DP from "../../assets/img/dp.png";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";

import { Footer } from "./shared/Footer";
import moment from "moment";
import useQuery from "../../hooks/useQueryHook";
import { sendOTP, userSignupPreAuth, verifyOTP } from "./Service";
import { detectBrowser, getDaysArray, months } from "../../utils";

export default function Signup() {
  const history = useHistory();
  const query = useQuery();

  const {
    firebaseToken,
    challenge,

    isCaptchaShow,
    isChallengeFound,
  } = useAuth();
  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [dobData, setDobData] = useState({
    month: "",
    days: getDaysArray(),
    year: [],
  });

  const [verificationCode, setVerificationCode] = useState("");

  const [captchaValue, setCaptchaValue] = useState("");

  const handleVerificationChange = (e) => {
    const { value } = e.target;
    setVerificationCode(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserDetails((prev) => {
      return { ...prev, [name]: value };
    });
    setError("");
    setErrorMobile("");
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmitPreAuth = async (e) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);

      const { country, dialCode, mobile } = userDetails;

      const phoneNumber = parsePhoneNumber(`${dialCode}${mobile}`, "IN");

      if (!phoneNumber?.isValid()) {
        return setErrorMobile("*Invalid Mobile Number");
      }

      const payload = {
        token: firebaseToken,
        number: `${dialCode}${mobile}`,
        deviceId: `12345678${detectBrowser()}`,
        deviceName: "rajesh_16",
        countryCode: country.id.toUpperCase(),
      };

      const res = await userSignupPreAuth(payload);
      setLoading(false);
      if (res?.response) {
        return setError(res?.response.data.message);
      }

      nextStepHandler(2);

      // history.push("/");
    } catch {
      setError("Failed to create an account");
    }

    setLoading(false);
  };

  const sendOTPHandler = useCallback(
    async (id) => {
      const { dialCode, mobile } = userDetails;
      const payload = {
        number: `${dialCode}${mobile}`,
      };
      if (isChallengeFound) {
        payload.challenge = challenge;
      } else {
        payload.captcha = captchaValue;
      }
      const res = await sendOTP(payload);
      console.log(res, "checkREs");
      //  setAuthSecretHandler(`${dialCode}${mobile}`);
    },
    [
      captchaValue,
      challenge,
      isChallengeFound,
      //setAuthSecretHandler,
      userDetails,
    ]
  );

  useEffect(() => {
    if (challenge) {
      sendOTPHandler();
    }
  }, [challenge, sendOTPHandler]);

  const generateArrayOfYears = useCallback(() => {
    var max = moment().subtract(18, "year").format("YYYY");
    var min = max - 20;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    setDobData((prev) => ({ ...prev, years }));
    getDaysArray(parseInt(years[0]), 1);
  }, []);

  useEffect(() => {
    generateArrayOfYears();
  }, [generateArrayOfYears]);

  useEffect(() => {
    history.push("?step=1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStepHandler = (step) => {
    history.push(`?step=${step}`);
  };

  const verifyOTPHandler = async (e) => {
    e.preventDefault();
    const payload = {
      capabilities: {
        storage: true,
        uuid: false,
        gv2: true,
      },
      fetchesMessages: false,
      pin: null,
      registrationId: 7095,
      registrationLock: null,
      signalingKey: null,
      unidentifiedAccessKey: "0CWLrBNvhVJ8lhPrJzelxw==",
      unrestrictedUnidentifiedAccess: false,
      isLogin: false,
    };
    const res = await verifyOTP(verificationCode, payload);

    console.log(res, "resData");
    nextStepHandler(3);
  };

  return (
    <>
      <div className="signup">
        <div className="signup_wrapper">
          <div className="head">
            <Logo />
            <div className="not_account_wrap">
              <Link to="/">Have an account? Sign In</Link>
            </div>
          </div>
          <div className="body">
            <ul className="step_ul">
              <li
                className={`step_one ${
                  query.get("step") == 1
                    ? "active selected"
                    : query.get("step") == 2 ||
                      query.get("step") == 3 ||
                      query.get("step") == 4 ||
                      query.get("step") == 5
                    ? "selected"
                    : ""
                }`}
              >
                <div className="nav_link contact">
                  <strong>Contact</strong> number
                </div>
                {query.get("step") == 1 && (
                  <div className="content_body">
                    <div className="card">
                      <h5>
                        What’s your
                        <br /> <strong>Contact</strong> number?
                      </h5>
                      <Form onSubmit={handleSubmitPreAuth}>
                        <Form.Group className="form-group">
                          <Form.Label>Select your country</Form.Label>

                          {/* <CountrySelect
                            value={userDetails.country}
                            onChange={handleCountryChange}
                            flags={true}
                          /> */}
                        </Form.Group>

                        <Form.Group
                          className={`form-group ${
                            errorMobile ? "error_border" : ""
                          }`}
                        >
                          <Form.Label>Mobile Number</Form.Label>

                          <InputGroup
                            className={`number_group ${
                              userDetails?.dialCode ? "dial_selected" : ""
                            }`}
                          >
                            <InputGroup.Text id="basic-addon1">
                              {userDetails?.dialCode}
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="mobile"
                              onChange={handleChange}
                              placeholder="Enter phone number"
                              value={userDetails?.mobile}
                              required
                            />
                          </InputGroup>

                          {errorMobile && (
                            <p className="text-danger text-left mb-0">
                              {errorMobile}
                            </p>
                          )}
                        </Form.Group>
                        <small>
                          Cachy will send you a text with a verification code on
                          mobile phone. Message and data rates may apply.
                        </small>
                        {error && (
                          <p className="text-danger text-center mb-1">
                            {error}
                          </p>
                        )}
                        <Button
                          disabled={loading}
                          className="w-100"
                          type="submit"
                        >
                          CREATE ACCOUNT
                        </Button>
                      </Form>
                    </div>
                  </div>
                )}
              </li>
              <li
                className={`step_two ${
                  query.get("step") == 2
                    ? "active selected"
                    : query.get("step") == 3 ||
                      query.get("step") == 4 ||
                      query.get("step") == 5
                    ? "selected"
                    : ""
                }`}
              >
                <div className="nav_link">Authentication</div>

                {query.get("step") == 2 && (
                  <div className="content_body">
                    <div className="card auth">
                      <h5>Authenticate</h5>
                      <Form onSubmit={verifyOTPHandler}>
                        {!isCaptchaShow ? (
                          <>
                            <Form.Group className="form-group">
                              <Form.Label>
                                Enter the code sent on +91 9876543210
                              </Form.Label>
                              <Form.Control
                                type="text"
                                required
                                onChange={handleVerificationChange}
                              />
                            </Form.Group>
                            <p>You need to enter the date you were born</p>
                            <h6>
                              Resend OTP <span>(57s)</span>
                            </h6>
                          </>
                        ) : (
                          <div className="captcha_wrap">
                            <ReCAPTCHA
                              sitekey="6LdBbogdAAAAAKdbiCilq7pEGQ2qUplcDFCVVyIL"
                              onChange={handleCaptchaChange}
                              size="normal"
                            />
                          </div>
                        )}

                        <Button
                          disabled={loading}
                          className="w-100"
                          type="submit"
                        >
                          CREATE ACCOUNT
                        </Button>
                        <Button variant="outline-primary">
                          ENTER MOBILE NUMBER
                        </Button>
                      </Form>
                    </div>
                  </div>
                )}
              </li>
              <li
                className={`step_three ${
                  query.get("step") == 3
                    ? "active selected"
                    : query.get("step") == 4 || query.get("step") == 5
                    ? "selected"
                    : ""
                }`}
              >
                <div className="nav_link">Your Name</div>
                {query.get("step") == 3 && (
                  <div className="content_body">
                    <div className="card your_name">
                      <h5>
                        Let us
                        <br /> <strong>Call</strong> you as…
                      </h5>
                      <Form>
                        <Form.Group className="form-group">
                          <Form.Label>First Name *</Form.Label>
                          <Form.Control type="text" required />
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control type="text" required />
                        </Form.Group>
                        <p className="info_text">
                          By default your name is not displayed in <br />
                          “Find Cachy calls”
                        </p>
                        <Button
                          disabled={loading}
                          className="w-100"
                          type="submit"
                          onClick={() => nextStepHandler(4)}
                        >
                          Next
                        </Button>
                      </Form>
                    </div>
                  </div>
                )}
              </li>
              <li
                className={`step_four ${
                  query.get("step") == 4
                    ? "active selected"
                    : query.get("step") == 5
                    ? "selected"
                    : ""
                }`}
              >
                {" "}
                <div className="nav_link">Your Birthday</div>
                {query.get("step") == 4 && (
                  <div className="content_body">
                    <div className="card birthday">
                      <h5>
                        When is your
                        <br /> <strong>Happy</strong> Birthday?
                      </h5>
                      <Form>
                        <Form.Group className="form-group">
                          <Form.Label>
                            You need to enter the date you were born
                          </Form.Label>
                          <div className="select_row">
                            <div className="select_col month">
                              <Form.Select size="lg">
                                {months.map((month, i) => (
                                  <option key={i} value={i + 1}>
                                    {month}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="select_col day">
                              <Form.Select size="lg">
                                {dobData?.days?.map((day, i) => (
                                  <option key={i} value={i + 1}>
                                    {day}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="select_col year">
                              <Form.Select size="lg">
                                {dobData?.years?.map((year, i) => (
                                  <option key={i} value={i + 1}>
                                    {year}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                        </Form.Group>
                        <p className="info_text">
                          This can’t be changed later.
                        </p>

                        <Button
                          disabled={loading}
                          className="w-100"
                          type="submit"
                          onClick={() => nextStepHandler(5)}
                        >
                          Next
                        </Button>
                        <Button variant="outline-primary" className="w-100">
                          Go Back
                        </Button>
                      </Form>
                    </div>
                  </div>
                )}
              </li>
              <li
                className={`step_five ${
                  query.get("step") == 5 ? "active selected" : ""
                }`}
              >
                {" "}
                <div className="nav_link">Your Profile Picture</div>
                {query.get("step") == 5 && (
                  <div className="content_body">
                    <div className="card dp">
                      <h5>
                        <strong>You</strong> are
                        <br /> Beautiful
                      </h5>

                      <div className="dp_wrap">
                        <img src={DP} alt="dp" />
                        <div className="add_dp">
                          <i className="bi bi-plus"></i>
                        </div>
                      </div>

                      <p className="info_text">
                        Your Profile picture will not be displayed
                        <br /> by Default on “Get Cachy” Calls.
                      </p>
                      <Button
                        disabled={loading}
                        className="w-100"
                        type="submit"
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom_auth">
          <h5>Get the app.</h5>
          <div className="download_app">
            <img src={AppStore} alt="app store" />
            <img className="ml-35" src={PlayStore} alt="app store" />
          </div>
        </div>
        <Footer extraItems />
      </div>

      {/* <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {currentUser?.email}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email"  required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div> */}
    </>
  );
}
