import React, { useEffect, useState } from "react";
import { Avatar, List } from 'antd';
import AvatarImage from "../AvatarImage";
import { useHistory } from "react-router-dom";

const SearchAccount = (props) => {
    const { accounts, bio } = props?.AccountData;
    const history = useHistory();

    return (
        <>
            {accounts?.length > 0 &&
                <>
                    <div className="search-account-heading">
                        <h2>people</h2>
                    </div>
                    <div className={`${accounts?.length > 3 && "account-data-wrapper"}`}>
                        <List
                            dataSource={accounts}
                            renderItem={(item, index) => (
                                <>
                                    <List.Item key={index}
                                        className="list-item-wrapper"
                                        onClick={() => history.push(`/${item.username}`)}
                                    >
                                        <List.Item.Meta
                                            avatar={<AvatarImage
                                                firstName={item.firstName}
                                                lastName={item.lastName}
                                                avatarImage={item.avatar} profileKey={item.profileKey}
                                            />}
                                            title={<a href={`/${item.username}`}>@{item.username}</a>}
                                            description={
                                                <>
                                                    {item.firstName}{" "} {item.lastName}
                                                </>}
                                        />
                                    </List.Item>
                                </>
                            )}
                        />
                    </div>
                </>
            }
            {bio?.length > 0 &&
                <>
                    <div className="search-account-heading bio-heading">
                        <h2>bio</h2>
                    </div>
                    <div className={`${bio?.length > 3 && "account-data-wrapper"}`}>
                        <List
                            dataSource={bio}
                            renderItem={(item, index) => (
                                <List.Item key={index}
                                    className="list-item-wrapper"
                                    onClick={() => history.push(`/${item.username}`)}
                                >
                                    <List.Item.Meta
                                        avatar={<AvatarImage
                                            firstName={item.firstName}
                                            lastName={item.lastName}
                                            avatarImage={item.avatar} profileKey={item.profileKey} />}
                                        title={<a href={`/${item.username}`}>@{item.username}</a>}
                                        description=
                                        {
                                            <>
                                                {item.firstName}{" "} {item.lastName}
                                                <h4>{item.bio}</h4>
                                            </>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                </>
            }

            {!accounts?.length && !bio?.length ?
                <>
                    <div className="search-account-wrapper">
                        <div className="nodata-wrapper ">
                            <h2>Oops! </h2>
                            <p> Please try something else. </p>
                        </div>
                    </div> 
                </> : ""
            }
            {/* {!accounts &&
                <div className="search-account-wrapper">
                    <div className="initial-search-data">
                        <span className="search-icon"> <i className="fas fa-search"></i></span>
                        <h4>Search Account</h4>
                    </div>
                </div>
            } */}

        </>
    )
}

export default SearchAccount;