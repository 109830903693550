import { Logo } from "../../pages/auth/shared/Logo";
import { Link } from "react-router-dom";

export default function PublicNavbar(props) {
  const { handleClick, login_btn } = props;
  return (
    <>
      <div className="publick_navbar">
        <Link className="navbar-brand" to="/">
          <Logo />
          {login_btn && <button onClick={handleClick} className="login-btn">login</button>}
        </Link>
      </div>
    </>
  );
}
