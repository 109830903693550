import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEGIN_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

const messaging = firebase.messaging();

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

export const getToken = (setToken) => {
  messaging
    .getToken({
      vapidKey:
        "BBUJU9X9rN4m6bk7NX88cPJXNjQqDcfjbyA7q7n1Ftld3z-KskNqX5ddYp2lXcS83FA4ZJmbTePDcyvaFe6DLAA",
    })
    .then((currentToken) => {
      if (currentToken) {
        setToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );

        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      console.log(err, "checkError");
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    messaging.onMessage((payload) => {
      resolve(payload);
      reject("faild");
    });
  });

export const auth = app.auth();
export default app;
