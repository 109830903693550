import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React from "react";
import { Button } from "react-bootstrap";
import TagsSkeleton from "./TagsSkeleton";

export default function AddTags(props) {
  const { onSubmit, tagList, onChange, selectedTagOne } = props; 

  return (
    <>
      <div className="tags_wrap">
        <div className="body">
          {tagList.length === 0 && <TagsSkeleton />}
          <ul>
            <RadioGroup defaultValue={selectedTagOne} name="tags">
              {tagList.map((tag) => ( 
                <li key={tag.id}>
                  {tag.name}{" "}
                  {/* <Form.Check
                  type="radio"
                  name="tags"
                  aria-label="radio 1"
                  onChange={() => onChange(tag)}
                /> */}
                  <Stack spacing={5} direction="row">
                    <div
                      style={{
                        border: "1px solid rgb(119, 119, 119, 0.2)",
                        borderRadius: "50%",
                        height: "18px",
                        width: "18px",
                      }}
                    >
                      <Radio
                        style={{ border: "none" }}
                        value={tag.name}
                        onChange={() => onChange(tag)}
                      ></Radio>
                    </div>
                  </Stack>
                </li>
              ))}
            </RadioGroup>
          </ul>
        </div>

        <div className="button_wrap">
          <Button type="submit" onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}
