/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from "react";
import { FEED_IMG_URL } from "../../utils/config";
import { useUser } from "../../context/UserProvider";
import { Avatar, Box, GridItem, useToast } from "@chakra-ui/react";
import moment from "moment";
import { FormControl, InputGroup } from "react-bootstrap";
import { postComment } from "./Service";
import { useHistory } from "react-router-dom";
import JSEMOJI from "emoji-js";
import Picker from "emoji-picker-react";
import Button from "@material-ui/core/Button";
import { Button as CustomButton } from "@chakra-ui/react";
import ReportModalPage from "../ReportModal/ReportModal";
import ContributorsDetails from "./ContributorsDetails";
import DefaultImage from "../../assets/img/image_placeholder.png";
import AvatarImage from "../../components/shared/AvatarImage";

/**
 * @author
 * @function FeedList
 **/

export const FeedList = (props) => {
  const {
    username,
    reference,
    onFeedClick,
    index,
    reportAbuseData,
    loginFeed,
    disableComment,
    handleDisable,
    sharePost,
  } = props;
  // console.log(onFeedLike,"onFeedLike");

  const data = {
    ...props.feedData,
    userName: props.feedData.userName
      ? props.feedData.userName
      : props.feedData?.contributorsDetails?.find(
          (p) => p?.uuid === props.feedData?.uuid
        )?.un
      ? props.feedData?.contributorsDetails?.find(
          (p) => p?.uuid === props.feedData?.uuid
        )?.un
      : username,
  };

  const history = useHistory();
  const { userData } = useUser();

  const [showPicker, setShowPicker] = useState(false);
  const [feedInputValue, setFeedInputValue] = useState();
  const [modalStatus, setModalStatus] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const isOwnPostForReport = userData?.uuid == data?.uuid;

  const refOne = useRef();
  const toast = useToast();

  const jsemoji = new JSEMOJI();

  jsemoji.img_set = "emojione";
  jsemoji.supports_css = false;
  jsemoji.allow_native = true;
  jsemoji.replace_mode = "unified";

  const onEmojiClick = (n, e) => {
    setFeedInputValue("");
    let emoji = jsemoji.replace_colons(`${e.emoji}`);
    setFeedInputValue(feedInputValue + emoji);
  };

  const handleFeedInputComment = (e) => {
    setShowPicker(false);
    setFeedInputValue(e.target.value);
  };

  const postCommentFeedList = async (e, postId) => {
    e.preventDefault();
    const payload = {
      postId,
      comment: feedInputValue,
    };
    setFeedInputValue("");
    const res = await postComment(payload);
    if (
      (res?.data?.code != 403) &
      (res?.data?.code != 415) &
      (res?.data?.code != 401)
    ) {
      toast({
        title: "Thank you for Comment",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: res?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const closePicker = (e) => {
      var path = e.path || (e.composedPath && e.composedPath());
      if (path[0]?.tagName !== "I") {
        setShowPicker(false);
      }
    };
    document.addEventListener("click", closePicker);
    return () => document.removeEventListener("click", closePicker);
  }, []);
  const swapDefaultImage = (e) => (e.target.src = DefaultImage);

  return (
    <GridItem colSpan={2} ref={reference}>
      <div className="feed_grid_col">
        <div>
          <div className="post_head">
            <div
              className="post_inner_head"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="post_avatar_un_head"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="post_head_avtar">
                  <Avatar
                    onClick={() =>
                      !loginFeed && history.push(`/${data.userName}`)
                    }
                    style={{ margin: "8px 8px", cursor: "pointer" }}
                    size="sm"
                    name={`${data?.firstName} ${data?.lastName}`}
                  />
                </div>
                <div
                  className="post_head_un_time"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    onClick={() =>
                      !loginFeed && history.push(`/${data.userName}`)
                    }
                    className="post_head_un"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    @{data.userName}
                  </div>
                  <div
                    className="post_head_time"
                    style={{
                      fontSize: "8px",
                      fontWeight: "bold",
                      color: "#707070",
                      cursor: "default",
                    }}
                  >
                    {moment(data.createdAt)
                      .format("DD-MMM-YYYY")
                      .replaceAll("-", " ")}
                  </div>
                </div>
              </div>

              {!loginFeed && (
                <Button
                  style={{ borderRadius: "50px" }}
                  onClick={() =>
                    isOwnPostForReport
                      ? setDeleteModal(true)
                      : setModalStatus(!modalStatus)
                  }
                >
                  <div
                    className="icon threedot_icon"
                    style={{ width: "20px" }}
                  ></div>
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="feed_body">
          <div
            className="feed_content"
            onClick={() => {
              onFeedClick(data, index);
            }}
          >
            <img
              src={`${FEED_IMG_URL}${data?.coverImg?.remoteid}`}
              onError={swapDefaultImage}
              alt=""
            />
            <div className="users">
              <ul>
                {data?.contributorsDetails?.map((c, index) => (
                  <ContributorsDetails
                    contributor={c}
                    key={index}
                    index={index}
                  />
                ))}
              </ul>
              {/* <span className="views_count">{data.views}</span> */}
            </div>
          </div>
          <div className="feed_bottom">
            <Box className="comment_wrap_box">
              {data?.comments?.length > 1 && (
                <p
                  className="view_all_text"
                  onClick={() => {
                    onFeedClick(data);
                  }}
                >
                  View all {data?.comments?.length} comments
                </p>
              )}
              {/* <p
                className="like_comment_details like-details"
                style={{ display: "flex", alignItems: "center" }}
              >
                {data.isLiked ? (
                  <>
                    <span
                      className="icon_sm liked"
                      style={{ marginRight: "4px" }}
                    ></span>
                    <span className="liked-by">Liked by</span>
                    <strong style={{ marginLeft: "2px" }}>
                      {data.isLiked ? "You " : ""}
                      {data?.likesCount > 0 && (
                        <>
                          and {data?.likesCount}{" "}
                          {data?.likesCount > 1 ? "others" : "other"}
                        </>
                      )}
                    </strong>
                  </>
                ) : (
                  <>
                    {data?.likesCount > 0 && (
                      <>
                        <span
                          className="icon_sm liked"
                          style={{ marginRight: "4px" }}
                        ></span>
                        <span>
                          {data.likesCount == 1
                            ? `${data.likesCount} Like`
                            : `${data.likesCount} Likes`}
                        </span>
                      </>
                    )}
                  </>
                )}
              </p> */}
              <h5 className="post_title" style={{ overflow: "hidden" }}>
                {data?.metadata?.length > 66 ? (
                  <span>
                    {data?.metadata?.slice(0, 66)}
                    <span
                      onClick={() => onFeedClick(data, index)}
                      style={{ fontWeight: "bolder", cursor: "pointer" }}
                    >
                      ...See more
                    </span>
                  </span>
                ) : (
                  data?.metadata
                )}
              </h5>
            </Box>
            <form
              onSubmit={(e) => postCommentFeedList(e, data.postId)}
              onClick={disableComment && handleDisable}
            >
              <InputGroup className={disableComment}>
                <InputGroup.Text>
                  <AvatarImage sharePost={sharePost} size="sm" />
                </InputGroup.Text>
                <FormControl
                  value={feedInputValue}
                  placeholder="Add a comment..."
                  onChange={(e) => handleFeedInputComment(e)}
                />
                <InputGroup.Text>
                  <CustomButton isDisabled={!feedInputValue} type="submit">
                    Post
                  </CustomButton>
                  {showPicker && (
                    <Picker
                      onEmojiClick={onEmojiClick}
                      disableSkinTonePicker
                      pickerStyle={{
                        position: "absolute",
                        bottom: "45px",
                        width: "80%",
                        right: "0",
                        zIndex: "9999",
                      }}
                    />
                  )}
                  <i
                    ref={refOne}
                    onClick={() => {
                      setShowPicker(!showPicker);
                      !feedInputValue && setFeedInputValue("");
                    }}
                    className="bi bi-emoji-smile"
                  ></i>
                </InputGroup.Text>
              </InputGroup>
            </form>
          </div>
        </div>
      </div>

      <ReportModalPage
        isOwnPostForReport={isOwnPostForReport}
        fromFeed
        modalStatus={modalStatus}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        setModalStatus={setModalStatus}
        feedData={data}
        postId={data.postId}
        reportAbuseData={reportAbuseData}
      />
    </GridItem>
  );
};
