import React from "react";
import UserList from "../../components/UserList";

export default function Suggestions(props) {
  const {
    popularList,
    peopleYouMayKnowList,
    followUserHandler,
    unfollowUserHandler,
  } = props;

  return (
    <>
      <div className="suggestions_wrap">
        {popularList.length > 0 && (
          <>
            <h5 className="title">Popular</h5>
            <div className="user_list_wrap mb_space">
              <ul>
                {popularList?.map((item, index) => (
                  <UserList
                    key={index}
                    fromSuggestion
                    data={item}
                    onFollow={followUserHandler}
                    onUnfollow={unfollowUserHandler}
                  />
                ))}
              </ul>
            </div>
          </>
        )}
        {peopleYouMayKnowList?.length > 0 && (
          <>
            <h5 className="title">People you may know</h5>
            <div className="user_list_wrap">
              <ul>
                {peopleYouMayKnowList?.map((item, index) => (
                  <UserList
                    key={index}
                    fromSuggestion
                    data={item}
                    onFollow={followUserHandler}
                    onUnfollow={unfollowUserHandler}
                  />
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
}
