import React from "react";

const PrivacyTable = (props) => {
    const { table_data } = props;
    return (
        <>
            <div className="table-main-wrapper">
                <table className="table-wrapper">
                    <thead>
                        <tr>
                            {table_data?.table_head?.map((thData, index) => (
                                <>
                                    <th key={index}>{thData.item}</th>
                                </>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table_data?.table_body?.map((tbody, index) => {
                            const { Activity_item, Activity_item_two, Activity_item_three, type_item, lawful_sub_item, lawful_sub_item_three,
                                lawful_child_item, technical, lawful_sub_item_two, usage, profile, type_sub_item, lawful_item, kyc, biometric } = tbody;
                            return (
                                <tr key={index}>
                                    <td>
                                        {Activity_item}
                                        <br />
                                        <ol>
                                            {Activity_item_two && <li>{Activity_item_two}</li>}
                                            {Activity_item_three && <li>{Activity_item_three}</li>}
                                        </ol>
                                    </td>
                                    <td >
                                        <ol>
                                            {type_item && <li>{type_item} </li>}
                                            {type_sub_item && <li>{type_sub_item}</li>}
                                            {kyc && <li>{kyc}</li>}
                                            {biometric && <li>{biometric}</li>}
                                            {profile && <li>{profile}</li>}
                                            {usage && <li>{usage}</li>}
                                            {technical && <li>{technical}</li>}
                                        </ol>
                                    </td>
                                    <td>
                                         {lawful_item}  
                                     {lawful_child_item && <><br/> {lawful_child_item}</>}   
                                        <ol>
                                            {lawful_sub_item_two &&<li>{lawful_sub_item_two}</li>} 
                                            {lawful_sub_item &&<li>{lawful_sub_item}</li>} 
                                            {lawful_sub_item_three &&<li>{lawful_sub_item_three}</li>} 
                                        </ol>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PrivacyTable