import Slide from "react-reveal/Slide";
import PublicNavbar from "../../components/shared/PublicNavbar";
import { Footer } from "../../components/shared/Footer";
import CreatorCard from "./creatorItem";
import creator_img1 from "../../assets/img/creator_img1.png";
import creator_img2 from "../../assets/img/creator_img2.png";
import creator_img3 from "../../assets/img/creator_img3.png";
import creator_img4 from "../../assets/img/creator_img4.png";
import creator_img5 from "../../assets/img/creator_img5.png";
import localData from "../creator/creator.json";
import { COPY_BASE_URL } from "../../utils/config";

export const Creator = () => {
  return (
    <>
      <section className="creator-wrapper">
        <PublicNavbar />
        <div className="creator-content-wrapper">
          <div className="creator-banner">
            <Slide bottom>
              <p>How to use like a Star?</p>
              <a
                href={`${COPY_BASE_URL}download`}
                rel="noreferrer"
                target="_blank"
              >
                <button>download</button>
              </a>
            </Slide>
          </div>
          {/* cards---start--here */}
          <div className="squad-card-wrap">
            <CreatorCard
              creatorImg={creator_img1}
              card_heading={localData.card_heading1}
              card_heading_break={localData.card_heading1_break}
              card_para_start={localData.card_para_start}
              card_content_bold={localData.card_para_bold}
              card_para_end={localData.card_para_end}
            />
          </div>
          <div className="own-talk-show card-wrap-reverse">
            <CreatorCard
              creatorImg={creator_img2}
              card_heading={localData.card_heading2}
              card_heading_break={localData.card_heading2_break}
              card_content={localData.card_content_ownTalk}
            />
          </div>
          <div className="own-talk-show">
            <CreatorCard
              creatorImg={creator_img3}
              card_heading={localData.card_heading3}
              card_heading_break={localData.card_heading3_break}
              card_content={localData.card_content_beReal}
            />
          </div>
          <div className="advantage card-wrap-reverse">
            <CreatorCard
              creatorImg={creator_img4}
              card_heading={localData.card_heading4}
              card_heading_break={localData.card_heading4_break}
              card_heading_break_last={localData.card_heading4_break_last}
              card_content={localData.card_content_beReal}
            />
          </div>
          <div className="make-money-wrap">
            <CreatorCard
              creatorImg={creator_img5}
              card_heading={localData.card_heading5}
              card_content={localData.card_content_beReal}
            />
          </div>
          {/* cards---end--here */}
        </div>
        <Footer extraItems />
      </section>
    </>
  );
};
