import { useState } from "react";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { CustomModal } from "./components/UI/Modal";
import { Button } from "@material-ui/core";

function App() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(false);
  const [title, setTitle] = useState(false);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // change-start
      const currentPath =  window.location.href.split("/")[3];
      const status = parseInt(error.response?.status);
  
      if (currentPath !== "" && [401, 440, 442].includes(status)) {
        let title, message;
  
        switch (status) {
          case 440:
            title = "Your Account Deleted!";
            message = "Your account has been permanently deleted as per your request. If you need further assistance or have any questions, please do not hesitate to contact us.";
            break;
          case 442:
            title = "Your Account Suspended!";
            message = "Your account has been suspended. Please contact support for further assistance.";
            break;
          default:
            title = "You're Logged Out!";
            message = "A new Login Session was created and hence you're logged out from this Session.";
            break;
        }
  
        setTitle(title);
        setMessage(message);
        setShow(true);
        // end
      } else {
        return Promise.reject(error);
      }
    }
  );
  const handleLogout = () => {
    setShow(false);
    localStorage.clear();
    window.location = "/";
  };

  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <CustomModal
        className="logoutButton"
        show={show}
        handleClose={setShow}
        title="You're Logged out!"
      > 
        A new Login Session was created and hence you're logged out from this 
        Session.
        <p style={{ display: "flex", justifyContent: "end" }}>
          <Button
            style={{
              background: "white",
              color: "Red",
              right: "0",
            }}
            onClick={handleLogout}
          >
            OK
          </Button>
        </p>
      </CustomModal>
    </>
  );
}

export default App;
