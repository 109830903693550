// import React, { Suspense } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useUser } from "../../context/UserProvider";
// import LeftFooter from "./LeftFooter";
// import SuggestionListOther from "./SuggestionListOther";

export default function VerticalForHam(props) {
  //   let location = useLocation();
  const { userData } = useUser();

  const LogoutHandler = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      <div
        className="hamburger_nav" 
      >
        <Nav className="flex-column" style={{ width: "fit-content" }}>
          <>
            <NavLink
              className="nav-link"
              style={{ padding: "1.5rem 1rem", fontSize: "25px" }}
              to={{
                pathname : `/${userData?.profile?.username}`,
                satete : {
                  userList : "",
                }
              }}
            >
              <i
                className="icon profile"
                style={{ width: "35px", height: "35px" }}
              ></i>{" "}
              Profile
            </NavLink>
            <NavLink
              style={{ padding: "1.5rem 1rem", fontSize: "25px" }}
              className="nav-link"
              to={`/edit/${userData?.profile?.username}`}
            >
              <i
                className="icon edit_profile"
                style={{ width: "35px", height: "35px" }}
              ></i>{" "}
              Edit Account
            </NavLink>
            <NavLink
              style={{ padding: "1.5rem 1rem", fontSize: "25px" }}
              className="nav-link"
              to={`/edit-help-support`}
            >
              <i
                className="icon help"
                style={{ width: "35px", height: "35px" }}
              ></i>{" "}
              Help and Support
            </NavLink>
            <NavLink
              style={{ padding: "1.5rem 1rem", fontSize: "25px" }}
              activeClassName="active"
              className="nav-link"
              to={`/edit-privacy-settings`}
            >
              <i
                className="icon lock"
                style={{ width: "35px", height: "35px" }}
              ></i>{" "}
              Privacy Settings
            </NavLink>
            <Nav.Link
              style={{ padding: "1.5rem 1rem", fontSize: "25px" }}
              eventKey="link-2"
              onClick={LogoutHandler}
            >
              <i
                className="icon logout"
                style={{ width: "35px", height: "35px" }}
              ></i>
              Log Out
            </Nav.Link>
          </>
        </Nav>
      </div>
    </>
  );
}
