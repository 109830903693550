import React, { useContext, useState } from "react";
import { getProfessionalTags } from "../pages/Profile/Service";

const TagsContext = React.createContext();

export function useTags() {
  return useContext(TagsContext);
}

export function TagsProvider({ children }) {
  const [allProfessionalTags, setAllProfessionalTags] = useState([]);
  const [selectedTagOne, setSelectedTagOne] = useState([]);
  const [selectedTagTwo, setSelectedTagTwo] = useState([]);
  const [tagType, setTagType] = useState(0);

  const getProfessionalTagsHandler = async () => {
    const res = await getProfessionalTags();
    if (res?.code === 200) {
      res.data.forEach((el) => {
        el.isChecked = false;
      });
      setAllProfessionalTags(res.data);
    }
  };
  const value = {
    allProfessionalTags,
    selectedTagOne,
    selectedTagTwo,
    tagType,
    setSelectedTagOne,
    setSelectedTagTwo,
    setTagType,
    getProfessionalTagsHandler,
    setAllProfessionalTags,
  };

  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
}
