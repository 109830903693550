import axios from "axios";
import { BASE_URL_3, BASE_URL_4 } from "../../utils/config";
let config = {
  baseURL: BASE_URL_3,
};
let newAxios = axios.create(config);

export const jobfilter = async () => {
  try {
    const { data } = await newAxios.get(
      `${BASE_URL_3}/cachy/v1/jobdetails/jobfilter`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getAllJobs = async () => {
  try {
    const { data } = await newAxios.post(`${BASE_URL_3}/cachy/v1/jobdetails`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getJobsByFilter = async (payload) => {
  try {
    const { data } = await newAxios.post(
      `${BASE_URL_3}/cachy/v1/jobdetails`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getJobDetailsById = async (id) => {
  try {
    const { data } = await newAxios.get(
      `${BASE_URL_3}/cachy/v1/jobdetails?id=${id}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const uploadFile = async (file) => {
  try {
    const { data } = await axios.post(`${BASE_URL_4}/updateImage`, file);
    return data;
  } catch (err) {
    return err;
  }
};
export const jobApply = async (payload) => {
  try {
    const { data } = await newAxios.post(
      `${BASE_URL_3}/cachy/v1/jobapply`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
