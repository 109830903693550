import axios from "axios";

export const getFollowingByUUID = async (uuid, page = 1) => {
  try {
    const { data } = await axios.get(
      `/cachy/v1/following/${uuid}?page=${page}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const unfollowUser = async (payload) => {
  try {
    const { data } = await axios.put(`/cachy/v1/unfollow`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFollowingByUUID,
  unfollowUser,
};
