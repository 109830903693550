import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import isEmpty from "is-empty";

import { Logo } from "../auth/shared/Logo";
import {
  getEarlyAccessContent,
  postEarlyAccess,
  sendMailEarlyAccess,
  verifyEmailEarlyAccess,
} from "./Service";
import { useLocation } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import validator from "validator";
import useQuery from "../../hooks/useQueryHook";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import { SOCKET_SERVER_URL } from "../../utils/config";
import { Footer } from "../../components/shared/Footer";
const smartTruncate = require("smart-truncate");

function EarlyAccess() {
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const query = useQuery();
  const [email, setEmail] = useState("");

  const [socketResponse, setSocketResponse] = useState();
  const [refCode, setRefCode] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");
  const [contentData, setContentData] = useState({});
  const [isEmailVerification, setIsEmailVerification] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [verifyData, setVerifyData] = useState({});

  const socketRef = useRef();

  const handleChange = (e) => {
    const { value } = e.target;

    setEmail(value);
    setError("");
  };
  const sendMailEarlyAccessHandler = async (e) => {
    e.preventDefault();
    const payload = {
      email,
    };
    if (query.get("referrer")) {
      payload.referrer = query.get("referrer");
    }

    if (!validator.isEmail(email)) {
      return setError("*Please use a valid email address");
    }
    const res = await sendMailEarlyAccess(payload);
    if (res.code === 200) {
      //  socketVerifyEmitHanlder(email);
      socketRef.current = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
      console.log(socketRef.current, "checkSocket");

      socketRef.current.on("connect", () => {
        console.log("connected");
      });
      socketRef?.current?.on(email, (data) => {
        console.log(email, "checkEmailTest");
        console.log(data, "testData");
        setSocketResponse(data);
      });

      setIsEmailVerification(true);
    } else if (res.code === 2016) {
      onOpen();
      setVerifyData(res.data);
    } else {
      setError(res?.message);
    }
  };

  const postEarlyAccessHandler = async () => {
    const payload = {
      email: query.get("email") || email,
    };
    if (refCode) {
      payload.referrer = refCode;
    }

    const res = await postEarlyAccess(payload);
    console.log(res, "resCode");
    if (res.code !== 401) {
      history.push("/");
      onOpen();
      setVerifyData(res.data);
    } else {
      toast({
        title: "Error",
        description: res.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (query.get("referrer")) {
      setRefCode(query.get("referrer"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEarlyAccessContentHanlder = async () => {
    const res = await getEarlyAccessContent();
    console.log(res, "resData");
    if (res.data) {
      setContentData(res.data);
    }
  };

  useEffect(() => {
    getEarlyAccessContentHanlder();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("validateverifyEmail")) {
      setIsEmailVerification(true);
      verifyEmailEarlyAccessHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyEmailEarlyAccessHandler = async () => {
    const res = await verifyEmailEarlyAccess(
      query.get("emailToken"),
      query.get("email")
    );

    console.log(res.response, "checkResponse");

    if (res?.response?.data?.code === 401) {
      setIsEmailVerification(false);
      return toast({
        title: "Error",
        description: res?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (res.code === 200) {
      setIsEmailVerification(false);
      console.log(socketRef?.current, "socketConnect");
      socketRef?.current?.on(query.get("email"), (data) => {
        console.log(email, "checkEmailTest");
        console.log(data, "testData");
        setSocketResponse(data);
      });
      postEarlyAccessHandler();
    } else if (res.code === 2016) {
      onOpen();
    } else {
      setError(res?.message);
    }
  };

  useEffect(() => {
    if (socketResponse?.status === "success") {
      postEarlyAccessHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketResponse]);

  console.log(socketResponse, "socketResponse");

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(verifyData?.bindUrl)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const truncated = smartTruncate(verifyData?.bindUrl, 40, { position: 21 });

  console.log(verifyData, "verifyData");

  return (
    <>
      <div
        className="early_access"
        //style={{ backgroundImage: `url(${contentData?.desktopimageUrl})` }}
      >
        <Logo />

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="wrap_box"
        >
          {!isEmailVerification ? (
            <>
              <Box>
                <Box className="head_box">
                  <Heading className="heading" textAlign={"center"}>
                    {contentData?.content && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${contentData?.content}`,
                        }}
                      />
                    )}
                  </Heading>
                  {/* <Heading className="heading" textAlign={"center"}>
                on our upcoming social networking platform!
              </Heading> */}
                </Box>

                <Box
                  display="flex"
                  justifyContent="center"
                  className="input_box"
                >
                  <Box>
                    <Box className="early_access_form_box">
                      <form onSubmit={sendMailEarlyAccessHandler}>
                        <Box>
                          <Input
                            placeholder="Enter your email"
                            onChange={handleChange}
                            className={error ? "error" : ""}
                          />
                          {error && (
                            <Text className="error_text" color="red" mt="1">
                              {error}
                            </Text>
                          )}
                        </Box>
                        <Box>
                          <Button
                            marginLeft="6px"
                            type="submit"
                            disabled={isEmpty(email)}
                          >
                            Get Early Access
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className="head_box">
                <Heading className="heading" textAlign={"center"}>
                  Please check your Email to verify your registration.
                </Heading>
                <Text className="sub_heading">
                  Can’t find anything in Inbox? Don’t forget to check the Spam
                  or Notification folder.
                </Text>
                {/* <Heading className="heading" textAlign={"center"}>
                on our upcoming social networking platform!
              </Heading> */}
              </Box>
            </>
          )}
        </Box>

        <Footer extraItems />
      </div>
      <ModalComponent
      
      isOpen={isOpen}
      onClose={onClose}
      className="whishlist_modal"
      style={{width:"91vw"}}
    >
      <Box className="whishlist_box" >
        <h4 style={{marginTop:"30px",marginBottom:"40px", textAlign: "center",fontWeight:"bold", fontSize:"28px" }}>You are successfully registered
on our waiting list!</h4>
        <Text className="ahead_text"></Text>
        <Box className="copy_box">
          <InputGroup size="sm">
            <Input
              value={truncated}
              readOnly
              onClick={(e) => e.preventDefault()}
            />
            <InputRightAddon
              children={isCopied ? "Copied!" : "Copy URL"}
              onClick={handleCopyClick}
            />
           
          </InputGroup>
          
        </Box>
        <Text>{verifyData?.message}</Text>
        <ul>
          <li>
            <FacebookShareButton
              url={verifyData?.bindUrl}
              className="icon-md facebook_md"
            />
            {/* <i className="icon-md facebook_md"></i> */}
          </li>
          {/* <li>
            <i className="icon-md instagram_md" />
          </li> */}
          <li>
            <TwitterShareButton
              url={verifyData?.bindUrl}
              className="icon-md twitter_md"
            />
          </li>
          <li>
            <EmailShareButton
              url={verifyData?.bindUrl}
              className="icon-md email_md"
            />
          </li>
          <li>
            <LinkedinShareButton
              url={verifyData?.bindUrl}
              className="icon-md linkedin_md"
            />
          </li>
          <li>
            <WhatsappShareButton
              url={verifyData?.bindUrl}
              className="icon-md whatsapp_md"
            />
          </li>
        </ul>
      </Box>
    </ModalComponent>
    </>
  );
}

export default EarlyAccess;
