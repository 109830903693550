/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Avatar } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "../../context/UserProvider";
import { followUser } from "../Followers/Service";
import { unfollowUser } from "../Following/Service";
import { handleProfile } from "./ProfileImageDecryption";

const ContributorsDetailsInner = ({ contributor }) => {
  const history = useHistory();
  const [contributorDetails, setContributorDetails] = useState(contributor);
  const [image, setImage] = useState("");
  const { userProfileData, setUserProfileData } = useUser();

  const isOwnProfile = userProfileData.uuid == contributor.uuid;

  const onFollowOnPostContributors = async (id) => {
    const payload = {
      followingUuids: [id],
    };
    const res = await followUser(payload);
    // res.isFollow == 1 &&
    //   setUserProfileData((prev) => ({
    //     ...prev,
    //     following: parseInt(userProfileData.following) + 1,
    //   }));
    setContributorDetails((prev) => ({ ...prev, isFollow: res.isFollow }));
  };

  const onUnfollowOnPostContributors = async (id) => {
    const payload = {
      unfollowUuids: [id],
    };
    const res = await unfollowUser(payload);

    // res.isFollow == 0 &&
    //   setUserProfileData((prev) => ({
    //     ...prev,
    //     following: parseInt(userProfileData.following) - 1,
    //   }));
    setContributorDetails((prev) => ({ ...prev, isFollow: res.isFollow }));
  };
  useEffect(() => {
    const ProfileImage = async () => {
      try {
        const res =
          (contributorDetails.ua.length != 0) &
            (contributorDetails.pk.length != 0) &&
          (await handleProfile(contributorDetails.ua, contributorDetails.pk));
        setImage(res);
      } catch (error) {
        return error;
      }
    };
    ProfileImage();
    return () => ProfileImage();
  }, []);
  return (
    <li>
      <Avatar
        style={{ cursor: "pointer", background: `${image ? "none" : ""}` }}
        onClick={() => history.push(`/${contributorDetails.un}`)}
        size="sm"
        src={image}
        name={`${contributorDetails.fn} ${contributorDetails.ln}`}
      />
      <p
        style={{ cursor: "pointer" }}
        onClick={() => history.push(`/${contributorDetails.un}`)}
      >
        @{contributorDetails.un}
      </p>

      {!isOwnProfile && (
        <>
          {contributorDetails.isFollow === 0 ? (
            <button
              className="btn btn-default sm"
              onClick={() =>
                onFollowOnPostContributors(contributorDetails.uuid)
              }
            >
              Follow
            </button>
          ) : contributorDetails.isFollow === 1 ? (
            <button
              className="btn btn-default sm is_following "
              onClick={() =>
                onUnfollowOnPostContributors(contributorDetails.uuid)
              }
            >
              Following
            </button>
          ) : (
            <button className="btn btn-default sm is_following ">
              Requested
            </button>
          )}
        </>
      )}
    </li>
  );
};

const memoContributor = React.memo(ContributorsDetailsInner);

export default memoContributor;
