import { Box } from "@chakra-ui/react";
import Avatar from "react-avatar";

import StoryItem from "./StoryItem";
import avatarImage from "../../assets/img/A_unnamed.png";
import avatarImage2 from "../../assets/img/A_nickbateman-Favim.com-3887490.png";
import avatarImage3 from "../../assets/img/A_21de8dbdbc13a234feb14ed6c5bf60b9.png";

export default function Stories({
  setStoryOpen,
  storyOpen,
  storiesList,
  onShow,
  handleSetVideoUrl,
  showPlayer,
  setPoster,
  setPlayerData,
  handleCardIndex,
}) {
  return (
    <>
      <div className="stories_wrapper">
        <div className="head">
          <h5>Stories</h5>
        </div>

        <div className="story_row">
          {storiesList.length > 0 &&
            storiesList.map((story, index) => (
              <StoryItem
                handleCardIndex={handleCardIndex}
                cardIndex={index}
                key={index}
                setStoryOpen={setStoryOpen}
                story={story}
                handleSetVideoUrl={handleSetVideoUrl}
                showPlayer={showPlayer}
                setPoster={setPoster}
                setPlayerData={setPlayerData}
              />
            ))}

          <div className="no_story_card">
            <Box className="avatar_box">
              <Avatar
                size={36}
                round={true}
                src={avatarImage}
                // maxinitials={2}
                name={`Ankit`}
              />
              <Avatar
                className="no_story_avatar"
                size={43}
                round={true}
                src={avatarImage3}
                // maxinitials={2}
                name={`Rajesh`}
              />
              <Avatar
                size={36}
                round={true}
                src={avatarImage2}
                // maxinitials={2}
                name={`Vaibhav`}
              />
            </Box>

            <div className="body">
              <p>Find more people to follow</p>
            </div>
            <div className="card_footer">
              <p onClick={onShow}>Show Suggestions</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
