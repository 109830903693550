import moment from "moment";
import React from "react";
import { Container, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";

/**
 * @author
 * @function Footer
 **/

export const Footer = (props) => {
  const { extraItems, backgroundColor, isBottom, fromAbout } = props;
  return (
    <>
      <footer
        className="public_footer"
        style={{
          backgroundColor: `${backgroundColor}`,
          position: `  ${
            fromAbout ? "relative" : isBottom ? "absolute" : "sticky"
          }`,
          bottom: `${isBottom ? "0" : "0"}`,
        }}
      >
        <Container>
          <Nav>
            <Nav.Item>
              <Link className="nav-link" to="/about">
                About
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="nav-link" to="/feature">
                Features
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="nav-link" to="creator">
                Creators
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="nav-link" to="/jobs">
                Jobs
              </Link>
            </Nav.Item>
            {extraItems && (
              <>
                <Nav.Item>
                  <Link className="nav-link" to="/help">
                    FAQ
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link className="nav-link" to="/privacy">
                    Privacy
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link className="nav-link" to="/terms">
                    Terms
                  </Link>
                </Nav.Item>
              </>
            )}
          </Nav>

          <div className="bottom_footer">
            <p>&copy; {moment(new Date()).format("YYYY")} Cachy Inc.</p>
          </div>
        </Container>
      </footer>
    </>
  );
};
