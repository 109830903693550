import axios from "axios";
import { BASE_URL_3 } from "../../utils/config";
let config = {
  baseURL: BASE_URL_3,
};
let newAxios = axios.create(config);

export const sendMailEarlyAccess = async (payload) => {
  try {
    const { data } = await newAxios.post(
      `${BASE_URL_3}/cachy/v1/earlyaccess/sendverifyEmail`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const postEarlyAccess = async (payload) => {
  try {
    const { data } = await newAxios.post(
      `${BASE_URL_3}/cachy/v1/earlyaccess`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getEarlyAccessContent = async () => {
  try {
    const { data } = await newAxios.get(
      `${BASE_URL_3}/cachy/v1/earlyaccess/getcontent`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const verifyEmailEarlyAccess = async (token, email) => {
  try {
    const { data } = await newAxios.get(
      `${BASE_URL_3}/cachy/v1/earlyaccess/validateverifyEmail?emailToken=${token}&email=${email}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
