import React, { useEffect, useState } from "react"; 
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar"; 
import TermsData from "../../locales/terms.json";
import TermsItem from "./TermsItem";

export default function Terms() { 

  return (
    <>
    <section className="public_wrapper privacy-main-wrapper terms-wrapper">
        <PublicNavbar /> 
        <div className="privacy_page"> 
            <TermsItem  privacydataItem={TermsData} />
        </div>
        <Footer extraItems />
      </section> 
    </>
  );
}
