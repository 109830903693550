import axios from "axios";
import { BASE_URL_3 } from "../utils/config";
let config = {
  baseURL: BASE_URL_3,
  headers: {
    Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
  },
};
let newAxios = axios.create(config); 
export const getPublicPageContent = async (slug) => {
  try {
    const { data } = await newAxios.get(`cachy/v1/pages?slug=${slug}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getFaq = async () => {
  try {
    const { data } = await newAxios.get(`cachy/v1/FAQ`);
    return data;
  } catch (err) {
    return err;
  }
};
