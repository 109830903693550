import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { Logo } from "../auth/shared/Logo";
import { Footer } from "../auth/shared/Footer";

function ThankYou() {
  return (
    <>
      <div className="login_wrapper thankyou_wrap">
        <Logo />
        <Box
          className="t_box"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box className="thankyou_box">
            <Box className="animation_box"></Box>
            <Box className="content_box">
              <i className="icon-md checked_md"></i>
              <Heading className="heading">Thank you</Heading>
              <Text className="text_sub">
                We have added to our waiting list
              </Text>
              <Text className="text_middle">Intrested in priority access?</Text>
              <Text className="text_last">
                Get early access by referring your friends. The more friends
                that join, the sooner you'll get access
              </Text>
              <ul>
                <li>
                  <i className="icon-md facebook_md"></i>
                </li>
                <li>
                  <i className="icon-md twitter_md"></i>
                </li>
                <li>
                  <i className="icon-md email_md"></i>
                </li>
                <li>
                  <i className="icon-md linkedin_md"></i>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
        <Footer extraItems />
      </div>
    </>
  );
}

export default ThankYou;
