/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import isEmpty from "is-empty";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";
import {
  getv2Keys,
  getVerificationCode,
  validateVerificationCode,
} from "./Service";
import { capabilities } from "../../utils";
import LocalStorageService from "../../utils/LocalStorageService";
import { useUser } from "../../context/UserProvider";
import { Box, Button, Grid, Input } from "@chakra-ui/react";
import { Footer } from "../../components/shared/Footer";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";
import { deviceDetect } from "react-device-detect";
import PublicNavbar from "../../components/shared/PublicNavbar";
import { Link } from "react-router-dom";
import { AppNavbar } from "../../components/shared/AppNavbar";
import LeftFooter from "../../components/shared/LeftFooter";
import VerticalNav from "../../components/shared/VerticalNav";
import { useLocation } from "react-router-dom";
import BrokenPage from "../../components/shared/BrokenPage";
import { getPostsWithoutLogin } from "../../components/layout/Service";
import { FeedList } from "../Feed/FeedList";
import LoginPostModal from "./LoginPostModal";
import { CustomModal } from "../../components/UI/Modal";
import { Logo } from "./shared/Logo";
import { Spinner } from "@chakra-ui/react";
import LoginModal from "../LoginModal/LoginModal";
export default function Login() {
  const [userLoginDetail, setUserLoginDetail] = useState({
    username: "",
    password: "",
  });
  const {
    setAuthSecretHandler,
    getSH512Salt,
    getArgon2Password,
    argonPassword,
    toUTF8Array,
    setIsCaptchaShow,
    setAuthSecretAfterLoginHandler,
    setChallenge,
  } = useAuth();
  const { onSubmitUserData } = useUser();
  const [error, setError] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [showEye, setShowEye] = useState(false);
  const [loginFlag, setLoginFlag] = useState(false);
  const [mobileLogin, setMobileLogin] = useState(false);
  const [posts, setPosts] = useState([]);
  const [provisiongResponse, setProvisioningResponse] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [diableLoginComment, setDiableLoginComment] = useState(false);

  let location = useLocation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserLoginDetail((prev) => ({ ...prev, [name]: value.trim() }));
    if (name === "password") {
      toUTF8Array(value.trim());
    }
    setError("");
  };

  const checkShaHandler = (e) => {
    const { value } = e.target;
    value.trim();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAuthSecretHandler(userLoginDetail.username);

    const { username } = userLoginDetail;
    setError("");
    setIsLogging(true);
    const payload1 = {
      isLogin: true,
      password: argonPassword,
      captcha: "",
      challenge: "",
    };
    try {
      const res = await getVerificationCode(username, payload1);
      setIsLogging(false);
      localStorage.setItem("deviceId", res.deviceId);
      if (!isEmpty(res?.response)) {
        localStorage.clear();
        setIsLogging(false);
        if (res.response.status === 411) {
          return setError("Maximum device limit reached");
        }
        return setError(res?.response?.data?.message);
      }

      res.deviceId && setProvisioningResponse(res);
      res.deviceId && setLoginFlag(true);
    } catch (error) {
      return error;
    }
  };
  const handleDeviceClose = () => setLoginFlag(false);
  const FinishLinkingHandler = async () => {
    try {
      const authKeyTemp = localStorage.getItem("secret");
      const payload = {
        capabilities,
        discoverableByPhoneNumber: true,
        fetchesMessages: true,
        pin: null,
        registrationId: 0,
        name: deviceName ? deviceName : deviceDetect().browserName,
        registrationLock: null,
        signalingKey: null,
        unidentifiedAccessKey: "",
        unrestrictedUnidentifiedAccess: false,
        video: true,
        voice: true,
        isLogin: true,
        password: argonPassword,
        AuthKey: authKeyTemp,
        captcha: "",
      };
      const res = await validateVerificationCode(
        provisiongResponse.verificationCode,
        provisiongResponse.deviceId,
        payload
      );

      if (res?.response?.status === 402) {
        localStorage.clear();

        return setIsCaptchaShow(true);
      } else if (res?.response?.status === 401) {
        localStorage.clear();

        setIsLogging(false);
        return setError("Invalid credentials!");
      }

      if (!isEmpty(res)) {
        setIsLogging(false);
        localStorage.setItem("isTokenValid", true);
        setAuthSecretAfterLoginHandler(res?.uuid, provisiongResponse.deviceId);
        if (!isEmpty(res) && res.status !== 404) {
          LocalStorageService.setItem("userProfileData", res);
          const lastPath = localStorage.getItem("lastPath");
          if (lastPath) {
            return (window.location.href = lastPath);
          }
          setChallenge("");
          onSubmitUserData(res);
          await getv2Keys(res?.profile?.identityKey, res?.profileKey);
          LocalStorageService.setItem("userData", res);
          window.location.href = "/feed";
          setIsLogging(false);
        }
      } else {
        localStorage.clear();
        setError("Something went wrong!");
        setIsLogging(false);
      }
    } catch (error) {
      setError("Failed to log in", "checkFaild");
    }
  };

  const handleEye = () => {
    setShowEye(!showEye);
    const res = document.getElementById("password123");
    if (res.type === "password") {
      res.type = "text";
    } else {
      res.type = "password";
    }
  };

  const handleClick = (post, index) => {
    setPlayerData(post);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const { password } = userLoginDetail;
    const salt = getSH512Salt(password.trim());
    getArgon2Password(salt);
  }, [userLoginDetail.password]);

  const getPostWithoutAuthHandler = async () => {
    const res = await getPostsWithoutLogin();
    setPosts(res.data);
  };

  useEffect(() => {
    getPostWithoutAuthHandler();
  }, []);

  const handleMobileLogin = () => {
    setMobileLogin(true);
  };
  const handleMobileLoginClose = () => {
    setMobileLogin(false);
  };

  const handleDisable = () => {
    setDiableLoginComment(true);
  };
  const handleDisableClose = () => {
    setDiableLoginComment(false);
  };

  return (
    <>
      <div class="new_login_wrapper">
        <PublicNavbar login_btn="login_btn" handleClick={handleMobileLogin} />
        <div className="wrapper">
          <div className="main_body">
            <Container>
              <div className="feed_row">
                <div className="feed_col_left sticky-top is_shadow">
                  <>
                    {/* <ProfileCard /> */}
                    <div
                      style={{
                        height: "62vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div className="card">
                        <h5 className="text_login">Log in</h5>
                        <Form onSubmit={handleSubmit}>
                          <Form.Group>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                              type="text"
                              name="username"
                              onChange={handleChange}
                              required
                              value={userLoginDetail.username}
                              maxLength={30}
                              placeholder="Username"
                            />
                          </Form.Group>
                          <div style={{ height: "22px" }}>
                            {error ===
                              "Note: username should be in lowercase" && (
                              <p
                                style={{ fontSize: "12px" }}
                                className="text-danger text-left mb-2"
                              >
                                {error}
                              </p>
                            )}
                          </div>

                          <Form.Group
                            style={{ position: "relative" }}
                            className="mb-3"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              id="password123"
                              name="password"
                              onChange={handleChange}
                              required
                              onBlur={checkShaHandler}
                              value={userLoginDetail.password}
                              placeholder="Password"
                              maxLength={30}
                            />
                            <p
                              className={`icon ${
                                showEye ? "eye" : "eye_hidden"
                              }`}
                              onClick={handleEye}
                              style={{
                                position: "absolute",
                                right: "15px",
                                top: "55%",
                                cursor: "pointer",
                              }}
                            ></p>
                          </Form.Group>

                          {error && (
                            <p className="text-danger text-center mb-2">
                              {error}
                            </p>
                          )}

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check type="checkbox" label="Remember Me" />
                          </Form.Group>

                          <Button
                            // isDisabled={!!error || !captchaValue}
                            isLoading={isLogging}
                            loadingText="Loging"
                            type="submit"
                          >
                            LOG IN
                          </Button>
                        </Form>
                      </div>
                    </div>
                    <LeftFooter />
                  </>
                </div>
                <Grid
                  h="full"
                  templateRows="repeat(2, 1fr)"
                  templateColumns="repeat(4, 1fr)"
                  gap={6}
                  mt="5"
                  mb="5"
                  className="feed_grid_row"
                >
                  {!posts.length > 0 ? (
                    <div className="post-loading">
                      <Spinner color="red.500" />
                    </div>
                  ) : (
                    <>
                      {posts?.map((post, index) => (
                        <>
                          <FeedList
                            loginFeed
                            key={index}
                            onFeedClick={handleClick}
                            index={index}
                            feedData={post}
                            disableComment="disableComment"
                            handleDisable={handleDisable}
                            sharePost
                          />
                        </>
                      ))}
                    </>
                  )}
                </Grid>
              </div>
            </Container>
            <LoginPostModal
              handleCloseModal={handleCloseModal}
              post={playerData}
              showModal={showModal}
            />
            <CustomModal
              crossIcon={true}
              className="choose-device-modal"
              show={loginFlag}
              handleClose={handleDeviceClose}
            >
              <div className="choose_device_name">
                <div class="choose_section">
                  <div>
                    <p>Choose this device's name</p>
                    <p>
                      You'll see this name under "Linked Devices" on your phone
                    </p>
                  </div>
                  <Input
                    onChange={(e) => setDeviceName(e.target.value)}
                    defaultValue={deviceDetect().browserName}
                    placeholder="Choose Device Name"
                    size="lg"
                  />
                  <Button
                    style={{ marginTop: "2em" }}
                    onClick={FinishLinkingHandler}
                  >
                    Finish Linking Phone
                  </Button>
                </div>
              </div>
            </CustomModal>
          </div>
          <CustomModal
            crossIcon={true}
            className="mobile-login-wrapper"
            show={mobileLogin}
            handleClose={handleMobileLoginClose}
            storyStatusClass={"mobile-login-backdrop"}
          >
            <div className="card">
              <div className="login-logo">
                <Logo />
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    onChange={handleChange}
                    required
                    value={userLoginDetail.username}
                    maxLength={30}
                    placeholder="Username"
                  />
                </Form.Group>
                <div>
                  {error === "Note: username should be in lowercase" && (
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-danger text-left mb-2"
                    >
                      {error}
                    </p>
                  )}
                </div>

                <Form.Group style={{ position: "relative" }} className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={showEye ? "text" : "password"}
                    id="password123"
                    name="password"
                    onChange={handleChange}
                    required
                    onBlur={checkShaHandler}
                    value={userLoginDetail.password}
                    placeholder="Password"
                    maxLength={30}
                  />
                  <p
                    className={`icon ${showEye ? "eye" : "eye_hidden"}`}
                    onClick={handleEye}
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "55%",
                      cursor: "pointer",
                    }}
                  ></p>
                </Form.Group>

                {error && (
                  <p className="text-danger text-center mb-2">{error}</p>
                )}

                <Form.Group
                  className="mb-3 d-none"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group>

                <Button
                  // isDisabled={!!error || !captchaValue}
                  isLoading={isLogging}
                  loadingText="Loging"
                  type="submit"
                  className="submit-btn"
                >
                  Log in
                </Button>
              </Form>
              <div className="about-mobile-login">
                <p>New to Cachy? Signup using our Mobile app.</p>
                <div className="social-login-link-wrap">
                  <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
                    <img src={PlayStore} alt="andoid app link" />
                  </a>
                  <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                    <img src={AppStore} alt="ios app link" />
                  </a>
                  <img />
                </div>
              </div>
            </div>
          </CustomModal>
          {/* mobile---login----end---here */}
          <LoginModal
            classValue="comment_share"
            mainHeading="Join the Conversation via Cachy Mobile."
            show={diableLoginComment}
            handleClose={handleDisableClose}
          />
        </div>
      </div>
    </>
  );
}
