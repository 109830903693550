import axios from "axios";
import { BASE_URL_3 } from "../../utils/config";

export const getReportAbuse = async (postId) => {
  let configs = {
    baseURL: BASE_URL_3,
    headers: {
      Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
    },
  };
  let newAxios = axios.create(configs);

  try {
    const { data } = await newAxios.get(
      `${BASE_URL_3}/cachy/v1/reportAbuse?type=account`
    );
    return data;
  } catch (error) {
    return { code: 500 };
  }
};

export const postReportAbuse = async (payload) => {
  try {
    const { data } = await axios.put(`/cachy/report`, payload);
    return data;
  } catch (error) {
    return { code: 422, message: "Already reported. " };
  }
};

export const getUserProfileById = async (id) => {
  try {
    const { data } = await axios.get(`cachy/v1/profile/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getUserProfileByUsername = async (username) => {
  try {
    const { data } = await axios.get(`cachy/v1/profile/view/${username}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getStorisOnClick = async (uuid) => {
  try {
    const { data } = await axios.get(`/cachy/v1/story/${uuid}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getPostByUUID = async (uuid) => {
  try {
    const { data } = await axios.get(`/cachy/v1/posts/${uuid}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getPostByUsername = async (username, page) => {
  try {
    const { data } = await axios.get(
      `/cachy/v1/user/posts/${username}?page=${page}`
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const getTaggedPost = async (uuid, page) => {
  try {
    const { data } = await axios.get(
      `/cachy/v1/tagged/posts/${uuid}?page=${page}`
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const updateProfile = async (payload) => {
  try {
    const { data } = await axios.put(`/cachy/v1/profile/update`, payload);
    return data;
  } catch (err) {
    return err.response;
  }
};

export const getProfessionalTags = async (payload) => {
  let config = {
    baseURL: BASE_URL_3,
    headers: {
      Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
    },
  };
  let reaxios = axios.create(config);

  try {
    const { data } = await reaxios.get(
      `${BASE_URL_3}/cachy/v1/professionalTags?page=0`
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const getWalletBalance = async () => {
  try {
    const { data } = await axios.get(`/cachy/v1/wallet/balance`);
    return data;
  } catch (err) {
    return err;
  }
};
