import React, { useContext, useState, useEffect } from "react";
import isEmpty from "is-empty";
import LocalStorageService from "../utils/LocalStorageService";
import { getUserProfile } from "../components/layout/Service";

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [userProfileData, setUserProfileData] = useState({
    firstName: "",
    lastName: "",
  });

  const [walletBalanceDetails, setWalletBalanceDetails] = useState({});

  const onSubmitUserData = (data) => {
    setUserData(data);
  };

  const onSubmitWalletBalanceDetails = (data) => {
    setWalletBalanceDetails(data);
  };

  useEffect(() => {
    const user = LocalStorageService.getItem("userData");
    setUserData(user);
  }, []);

  const getUserProfileHandler = async (uuid) => {
    const res = await getUserProfile(uuid);
    if (!isEmpty(res)) {
      LocalStorageService.setItem("userProfileData", res);
      setUserProfileData(res);
    }
  };

  const getUserProfileData = () => {
    const user = LocalStorageService.getItem("userProfileData");
    if (user) {
      setUserProfileData(user);
    }
  };

  useEffect(() => {
    getUserProfileData();
  }, []);

  const value = {
    userData,
    userProfileData,
    setUserData,
    walletBalanceDetails,
    onSubmitUserData,
    getUserProfileHandler,
    getUserProfileData,
    onSubmitWalletBalanceDetails,
    setUserProfileData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
