import axios from "axios";
import { TYPE } from "../../utils/config";

export const userSignupPreAuth = async (payload) => {
  try {
    const { data } = await axios.get(
      `cachy/v1/accounts/${TYPE.fcm}/login/${payload.token}/${payload.number}/${payload.deviceId}/${payload.deviceName}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getVerificationCode = async (username, payload) => {
  try {
    const { data } = await axios.put(
      `cachy/v1/devices/provisioning/code/${username}`,
      payload
    );
    return data;
  } catch (err) {
    localStorage.clear();
    return err;
  }
};
export const validateVerificationCode = async (
  verificationCode,
  deviceId,
  payload
) => {
  try {
    const { data } = await axios.put(
      `/cachy/v1/devices/${verificationCode}/${deviceId}`,
      payload
    );
    return data;
  } catch (err) {
    localStorage.clear();
    return err;
  }
};

export const logOut = async () => {
  const deviceId = localStorage.getItem("deviceId");
  try {
    const { data } = await axios.delete(`/cachy/v1/devices/${deviceId}`);
    return data;
  } catch (err) {
    localStorage.clear();
    return err;
  }
};

export const sendOTP = async (payload) => {
  try {
    const { data } = await axios.get(
      `cachy/v1/accounts/${TYPE.sms}/code/${payload.number}?client=CACHYWEB&challenge=${payload.challenge}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const verifyOTP = async (code, payload) => {
  try {
    const { data } = await axios.put(`cachy/v1/accounts/code/${code}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getv2Keys = async (identityKey, profileKey) => {
  const payload = {
    identityKey: identityKey,
    preKeys: [
      {
        keyId: 7824934,
        publicKey: "BRDxKnWDALYZNmnHIsPtUNdWVFNYZi1B/5x4crB9De1C",
      },
      {
        keyId: 7825033,
        publicKey: "BaECdX0dETM8jvq3pw4fAe9rkC7aFZ0myY7DYB4h8g1h",
      },
    ],
    profileKey: profileKey,
    signedPreKey: {
      keyId: 4998828,
      publicKey: "BXlTEv/rexoMPgBcyBcjONCqGtuvF+Ork7aSFSqgiX9Y",
      signature:
        "I4XxpQ02ZCnAHSERrpiqJzjo0w31IpJsOteggoQbgNBKqFylvZKGHNkDk+/AT99GPPGlkk+6lt02z1CtpIlHhw",
    },
  };

  try {
    const { data } = await axios.put(`cachy/v2/keys/`, payload);

    return data;
  } catch (err) {
    return err;
  }
};

export const userLogin = async (payload) => {
  try {
    const { data } = await axios.get(
      `cachy/v1/accounts/fcm/login/${payload.token}/${payload.username}/${payload.deviceId}/${payload.deviceName}/`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const accountVerifyLogin = async (username, payload) => {
  try {
    const { data } = await axios.put(
      `cachy/v1/accounts/verify/${username}`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const accountTokenRefresh = async (payload) => {
  try {
    const { data } = await axios.put(`cachy/v1/accounts/gcm/`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getAuthHeaders = async () => {
  try {
    const { data } = await axios.get(`cachy/v1/storage/auth`);
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userSignupPreAuth,
  sendOTP,
  verifyOTP,
  userLogin,
  logOut,
  accountTokenRefresh,
  getAuthHeaders,
};
