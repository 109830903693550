import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoutes = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("auth_secret");
  return (
    <Route
      {...rest}
      render={(props) => {
        // if (!token && props.location.pathname !== "login") {
        //   localStorage.setItem(
        //     "lastPath",
        //     props.location.pathname + props.location.search
        //   );
        // }
        return token ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default AuthRoutes;
