import React from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

const PreviewLink = ({ url, imagePoster, title, metaDescription }) => ( 
    <Helmet>
        <title>{!title ? title : "Cachy - Beyond Flash Social"}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" key="description" content={metaDescription} data-react-helmet="true" />
        <meta name="title" key="title" content={title} data-react-helmet="true" />
        <meta property="og:title" key="og:title" content={title} data-react-helmet="true" />
        <meta property="og:locale" key="og:locale" content="en_US" data-react-helmet="true" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" data-react-helmet="true" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={imagePoster} />
        <meta property="whatsapp:text" content={metaDescription} />
        <meta
            property="og:description"
            key="og:description"
            content={metaDescription} data-react-helmet="true"
        />
        <meta
            property="og:image"
            key="og:image"
            content={imagePoster} data-react-helmet="true"
        />
    </Helmet>
);

export default withRouter(PreviewLink);
