import React, { useEffect, useState, Fragment } from "react";
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar";
import faq from "../../locales/faq.json";
import FaqItem from "./faqItem";

export const Help = () => {
  return (
    <>
      <section className="public_wrapper privacy-main-wrapper faq-main-wrapper">
        <PublicNavbar />
        <div className="privacy_page">
          <FaqItem faq={faq} />
        </div>
        <Footer extraItems />
      </section>
    </>
  );
}
