import React from "react";
import { SkeletonText, Box } from "@chakra-ui/react";

function JobCardSkeleton() {
  return (
    <>
      <Box className="job_card">
        <SkeletonText noOfLines={3} spacing="4" height="60px" width="50%" />
      </Box>
    </>
  );
}

export default JobCardSkeleton;
