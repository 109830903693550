import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";
import moment from "moment";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";

function LeftFooter() {
  return (
    <>
      <div className="right_footer">
        <ul>
          <li>
            <Link to="/about" target="_blank">
              About
            </Link>
          </li>
          <li>
            <Link to="/feature" target="_blank">
              Features
            </Link>
          </li>
          <li>
            <Link to="/creator" target="_blank">
              Creators
            </Link>
          </li>
          <li>
            <Link to="/help" target="_blank">
              FAQ
            </Link>
          </li>
          <li>
            <Link to="/Jobs" target="_blank">
              Jobs
            </Link>
          </li>
          <li>
            <Link to="/privacy" target="_blank">
              Privacy
            </Link>
          </li>
          <li>
            <Link to="/terms" target="_blank">
              Terms
            </Link>
          </li>
        </ul>
        <Box
          display="flex"
          justifyContent="space-between"
          mt="2"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            className="social-footer"
          >
            <p>&copy; {moment(new Date()).format("YYYY")} Cachy Inc.</p>
            <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
              <img src={PlayStore} alt="app store" />
            </a>
            <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
              <img src={AppStore} alt="app store" />
            </a>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default LeftFooter;
