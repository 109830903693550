/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { handleProfile } from "../../pages/Feed/ProfileImageDecryption";
import { useHistory } from "react-router-dom";

const SuggestionUserList = ({
  person,
  followUserHandler,
  unfollowUserHandler,
}) => {
  const history = useHistory();

  const [image, setImage] = useState("");

  useEffect(() => {
    const ProfileImage = async () => {
      try {
        if (person.avatar && person.profileKey) {
          const res = await handleProfile(person.avatar, person.profileKey);
          setImage(res);
        }
      } catch (error) {
        return error;
      }
    };
    ProfileImage();
  }, []);

  return (
    <>
      <li key={person.uuid}>
        <div className="media">
          <div style={{ position: "relative", width: "56px" }}>
            <CircularProgressbarWithChildren
              style={{ position: "absolute" }}
              value={100}
              strokeWidth={3}
              styles={buildStyles({
                pathColor: !person.isUserStoryExists ? "none" : "#169D59",
                trailColor: "none",
              })}
            >
              <Avatar
                style={{ cursor: "pointer", background: `${image && "none"}` }}
                onClick={() => history.push(`/${person?.username}`)}
                size="md"
                src={image}
                round="true"
                maxinitials={2}
                name={`${person.firstName} ${person.lastName}`}
              />
            </CircularProgressbarWithChildren>
          </div>
          <div className="details">
            <p onClick={() => history.push(`/${person.username}`)}>
              {person?.firstName} {person?.lastName}
              {/* {
            <i className={`icon ${person}`}></i>
        }
        */}
              {person.premium ? (
                <i style={{ marginLeft: "5px" }} className="icon premium"></i>
              ) : !person.professional ? (
                ""
              ) : person.professional == 1 ? (
                <i style={{ marginLeft: "5px" }} className="icon expert"></i>
              ) : (
                <i
                  style={{ marginLeft: "5px" }}
                  className="icon professional"
                ></i>
              )}
            </p>
            <h5 onClick={() => history.push(`/${person.username}`)}>
              @{person?.username}
            </h5>
          </div>
        </div>
        {person.isFollow ? (
          person.isFollow == 1 ? (
            <Button onClick={() => unfollowUserHandler(person.uuid)}>
              Following
            </Button>
          ) : (
            <Button>Requested</Button>
          )
        ) : (
          <Button
            onClick={() =>
              followUserHandler(person.uuid, person.privateAccount)
            }
            className="follow_btn isFollowing"
          >
            Follow
          </Button>
        )}
      </li>
    </>
  );
};

export default SuggestionUserList;
