import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import SearchAccount from "./SearchAccount";
import PostSearch from "./PostSearch";

const SearchMain = (props) => {
  const {
    AccountData,
    postValue,
    handleSearchClose,
    setPostOnScroll,
    spinnerLoading,
  } = props;

  const postData = postValue !== undefined && postValue;

  return (
    <>
      <div className="closeSearch" onClick={handleSearchClose}>
        <span>
          <i className="fas fa-times"></i>
        </span>
      </div>
      <Tabs
        defaultActiveKey={`${!AccountData.accounts ? "Account" : "Post"}`}
        className="mb-3"
      >
        <Tab eventKey="Post" title="Post" className="post-title">
          <div className="post-body-wrapper account-content">
            <PostSearch
              spinnerLoading={spinnerLoading}
              setPostOnScroll={setPostOnScroll}
              postData={postData}
            />
          </div>
        </Tab>
        <Tab eventKey="Account" title="Account" className="account-title">
          <div className="account-content">
            <div className="account-search-content">
              <SearchAccount AccountData={AccountData} />
            </div>
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default SearchMain;
