import { Avatar } from "@chakra-ui/react";
import React from "react";

const MentionList = ({
  profiles,
  setPostFeedComment,
  handleMention,
  postFeedComment,
  key,
}) => {
  const checkUsername = (profile) => {
    handleMention(profile.uuid);
    const indexOfAt = postFeedComment.lastIndexOf("@");
    const slicedValue = postFeedComment.slice(indexOfAt + 1);
    const slicedUsername = postFeedComment.replace(
      slicedValue,
      profile.username
    );
    setPostFeedComment(slicedUsername);
  };
  return (
    <>
      {profiles.length ? (
        <div className="mention">
          <div className="mentionInner">
            <div>
              {profiles.map((e) => (
                <>
                  <div
                    key={key}
                    onClick={() => checkUsername(e)}
                    style={{
                      display: "flex",
                      padding: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Avatar size="sm" name={`${e?.firstName} ${e?.lastName}`} />
                    <div style={{ display: "inline-block", marginLeft: "8px" }}>
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        @{e.username}
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          color: "#707070",
                        }}
                      >
                        {`${e.firstName}${e.lastName}`}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MentionList;
