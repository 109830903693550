import { useState } from "react";
import {
  // Button,
  Container,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Logo } from "../../pages/auth/shared/Logo";
import { Link } from "react-router-dom";

import { useUser } from "../../context/UserProvider";
// import Notifications from "./Notifications";
import { Sling as Hamburger } from "hamburger-react";
import VerticalForHam from "./VerticalForHam";
import AvatarImage from "./AvatarImage";
import {
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Spinner,
} from "@chakra-ui/react";
import SearchIcon from "../../assets/img/search-icon.png";
/**
 * @author
 * @function AppNavbar
 **/

export const AppNavbar = (props) => {
  const { userData, userProfileData } = useUser();
  const [hamburger, setHamburger] = useState(false);
  // const [headerSearch, setHeaderSearch] = useState(false);
  const { loading, value, handleInputEmpty } = props;

  return (
    <>
      <div className="app_nav sticky-top">
        <Container className="navbar_container">
          <Navbar expand="lg">
            <Navbar.Brand>
              <Link to="/">
                <Logo />
              </Link>
            </Navbar.Brand>

            <Navbar className="left_nav" id="basic-navbar-nav">
              <Nav>
                <div className="header-input-wrapper">
                  <input
                    placeholder="search"
                    onChange={props?.handleSearch}
                    value={value}
                  />
                  {value && (
                    <div
                      class="postsearch-spinner cross-icon"
                      onClick={handleInputEmpty}
                    >
                      <i className="fas fa-times"></i>
                    </div>
                  )}
                  {loading && (
                    <>
                      <div class="postsearch-spinner">
                        <Spinner color="red.500" />{" "}
                      </div>
                    </>
                  )}
                  <div
                    className="search-icon-wrapper"
                    onClick={props?.handleSearch}
                  >
                    <img src={SearchIcon} alt="search-icon" />
                  </div>
                </div>

                {/* don't delete this code maybe we can use this in future */}
                {/*   {headerSearch &&
               <div className="mobile-header-wrap">
                  <div className="header-input-wrapper">
                    <input
                      placeholder="search"
                      onChange={props?.handleSearch}
                      value={value}
                    />
                    {value && <div class="postsearch-spinner cross-icon" onClick={handleInputEmpty}>
                      <i className="fas fa-times"></i>
                    </div>
                    }
                    {loading &&
                      <>
                        <div class="postsearch-spinner"><Spinner color="red.500" /> </div>
                      </>
                    }
                    <div className="search-icon-wrapper"
                      onClick={props?.handleSearch}
                    >
                      <img src={SearchIcon} alt="search-icon" />
                    </div>
                  </div>
                </div>
                }
                <div className="mobile-search-icon"
                  onClick={() => setHeaderSearch(!headerSearch)}>
                  <span>
                    <i className="fas fa-search"></i></span>
                </div> */}
                {userProfileData.firstName ? (
                  <AvatarImage size="sm" />
                ) : (
                  <SkeletonCircle color="gray" size="10" />
                )}
                {/* </Link> */}
                <Link
                  to={`/${userData?.profile?.username}`}
                  className="nav-link username_link"
                >
                  {userProfileData.firstName ? (
                    `${userProfileData?.firstName} ${userProfileData?.lastName}`
                  ) : (
                    <Skeleton
                      color="gray"
                      height="20px"
                      width={100}
                      borderRadius="25"
                    />
                  )}
                </Link>
              </Nav>
              <span className="hamburger">
                <Hamburger
                  size={25}
                  onToggle={() => setHamburger(!hamburger)}
                />
              </span>
            </Navbar>
          </Navbar>
          {hamburger && <VerticalForHam />}
        </Container>
      </div>
    </>
  );
};
