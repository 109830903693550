import axios from "axios";

export const updatePrivacySetting = async (payload) => {
  try {
    const { data } = await axios.put(`/cachy/v1/privacy/settings`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const getPrivacySetting = async () => {
    try {
      const { data } = await axios.get(`/cachy/v1/privacy/settings`);
      return data;
    } catch (err) {
      return err;
    }
  };

