import React, { useState, useEffect } from "react";
import { Grid, Spinner } from "@chakra-ui/react";
import { Avatar, List } from "antd";
import { FeedList } from "../FeedList";

import {
  commentLike,
  feedLike,
  getCommentsByPost,
  getReportAbuseFeed,
  replyCommentLike,
} from "../../Feed/Service";
import PostModal from "../PostModal/PostModal";
import { FEED_IMG_URL } from "../../../utils/config";

const PostSearch = (props) => {
  const { postData, setPostOnScroll, spinnerLoading } = props;
  const [postModal, setPostModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [commentsOnPost, setCommentOnPost] = useState([]);
  const handlePostSearchModal = () => setPostModal(true);
  const handlePostSearchModalClose = () => setPostModal(false);
  const [videoPoster, setVideoPoster] = useState({});
  const [playerData, setPlayerData] = useState({});
  const [reportAbuseData, setReportAbuseData] = useState();
  const [page, setPage] = useState(1);
  const getCommentsByPostHandler = async (postId) => {
    const res = await getCommentsByPost(postId);
    if (res.data) {
      setCommentOnPost(res.data);
    }
  };

  const handleClick = async (data) => {
    handlePostSearchModal();
    setPlayerData(data);
    getCommentsByPostHandler(data?.postId);
    setPlayerData(data);
    setVideoPoster(`${FEED_IMG_URL}${data?.coverImg?.remoteid}`);
    setVideoUrl(`${FEED_IMG_URL}${data?.video?.remoteid}`);
  };

  const handleSubmitComments = (data) => {
    console.log(data);
  };

  const handleFeedLike = async (data) => {
    const payload = {
      isLike: playerData.isLiked,
      postId: data.postId,
    };
    const res = await feedLike(payload);
    if (res.count) {
      setPlayerData((prev) => ({
        ...prev,
        isLiked: !data.isLiked,
        likesCount: res.count,
      }));
    }
  };

  const handleCommentLike = async (data) => {
    const payload = {
      commentId: data.id,
      isLike: !data.isLiked,
    };
    const res = await commentLike(payload);
    if (res.count) {
      setCommentOnPost((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === data.id) {
            return {
              ...comment,
              isLiked: !comment.isLiked,
              likesCount: res.count,
            };
          }
          return comment;
        })
      );
    }
  };

  const handleReplyLike = async (commentData, reply) => {
    const payload = {
      commentId: reply.id,
      isLike: !reply.isLiked,
      isReplyLike: true,
    };

    const res = await replyCommentLike(payload);

    if (res.count) {
      setCommentOnPost((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === commentData.id) {
            return {
              ...comment,
              reply: comment.reply.map((r) => {
                if (r.id === reply.id) {
                  return {
                    ...r,
                    isLiked: !r.isLiked,
                    likesCount: res.count,
                  };
                }
                return r;
              }),
            };
          }
          return comment;
        })
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let reportData = await getReportAbuseFeed();
      setReportAbuseData(reportData?.data?.story_post.DEFAULT);
    };
    fetchData();
  }, []);

  const handlePostScroll = (event) => {
    const { scrollHeight, scrollTop, offsetHeight } = event.currentTarget;

    if (scrollTop + offsetHeight + 2 > scrollHeight) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    setPostOnScroll(page);
  }, [page]);

  return (
    <>
      <div className="post-search-wrap" onScroll={(e) => handlePostScroll(e)}>
        {!postData?.length ? (
          <div className="search-account-wrapper">
            <div className="nodata-wrapper">
              <h2>Oops! </h2>
              <p> Please try something else. </p>
            </div>
          </div>
        ) : (
          <Grid
            h="full"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={4}
            mt="5"
            mb="5"
            className="feed_grid_row"
          >
            {postData?.map((Feed, index) => (
              <>
                <FeedList
                  key={index}
                  onFeedClick={handleClick}
                  index={index}
                  feedData={Feed}
                  reportAbuseData={reportAbuseData}
                />
              </>
            ))}
            {spinnerLoading && (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "70px",
                  height: "30px",
                }}
              >
                <Spinner color="red.500" />
              </p>
            )}
          </Grid>
        )}
      </div>

      <div className="post-modal-wrapper">
        <PostModal
          videoUrl={videoUrl}
          videoPoster={videoPoster}
          playerData={playerData}
          postModal={postModal}
          handleFeedLike={handleFeedLike}
          handleClose={handlePostSearchModalClose}
          commentsOnPost={commentsOnPost}
          handleSubmitComments={handleSubmitComments}
          handleCommentLike={handleCommentLike}
          getCommentsByPostHandler={getCommentsByPostHandler}
          handleReplyLike={handleReplyLike}
          reportAbuseData={reportAbuseData}
        />
      </div>
    </>
  );
};

export default PostSearch;
