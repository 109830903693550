import React, { useEffect, useState } from "react"; 
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar"; 
import PrivacyData from "../../locales/privacy.json";
import PrivacyItem from "./privacyitem";
export default function Privacy() {

  return (
    <>

      <section className="public_wrapper privacy-main-wrapper">
        <PublicNavbar />
        <div className="privacy_page">
          <PrivacyItem privacydataItem={PrivacyData} />
        </div>
        <Footer extraItems />
      </section>

    </>
  );
}
