import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import React from "react";

export default function UserListSkeleton() {
  return (
    <>
      <div className="user_list_wrap">
        <ul>
          <li>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" width="100%" alignItems="center">
                <SkeletonCircle size="10" />
                <SkeletonText noOfLines={2} width="30%" ml="3" />
              </Box>
            </Box>
          </li>
        </ul>
      </div>
    </>
  );
}
