import { Avatar } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { handleProfile } from "../../pages/Feed/ProfileImageDecryption";

const AvatarImage = (props) => {
    const { profileKey, avatarImage,firstName,lastName } = props;
    const [image, setImage] = useState("")  
    
    useEffect(() => {
        const ProfileImage = async () => {
            try {
                if (avatarImage && profileKey) {
                    const res = await handleProfile(avatarImage, profileKey);
                    res && setImage(res);
                }
            } catch (error) {
                return error;
            }
        };
        ProfileImage();
        return () => ProfileImage();
    }, [avatarImage, profileKey]);

    return (
        <>
           <Avatar src={image} alt="avatar"  name={`${firstName} ${lastName}`}/>
        </>
    )
}

export default AvatarImage;