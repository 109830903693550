import { Redirect, Switch, Route } from "react-router-dom";
import AuthRoutes from "../routes/AuthRoutes";



import ScrollToTop from "../utils/ScrollTop";
import { UserProvider } from "../context/UserProvider";
import { AuthProvider } from "../context/AuthContext";
import { TagsProvider } from "../context/TagsProvider";
import EarlyAccess from "../pages/EarlyAccess";
import ThankYou from "../pages/EarlyAccess/ThankYou";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import About from "../pages/about";
import { Creator } from "../pages/creator";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import { Help } from "../pages/Help";
// import { Feature } from "../pages/Feature";
import UnAuthRoutes from "../routes/UnAuthRoutes";
import Jobs from "../pages/Jobs";
import JobDetails from "../pages/Jobs/JobDetails";
import SharePost from "../pages/SharePost/SharePost";
import SharePostLogin from "../pages/LoginModal/SharePostLogin";
import MainFeature from "../pages/Feature2";
import CommunityGuidelines from "../pages/CommunityGuideline/CommunityGuidelines";
import CommercialTerms from "../pages/CommercialTerms/CommercialTerms";
import DataPolicy from "../pages/DataPolicy/DataPolicy";
import FaqData from "../pages/FrequentlyAskQuestions/FaqData";
import BetaPage from "../pages/BetaPage/BetaPage";
import PrivateLayout from "../components/layout/PrivateLayout";
import DownloadApp from "../pages/DownlaodPage/DownloadPage";
import DeleteAccount from "../pages/DeleteAccount";

//let count = 0;
const Routes = () => {
  // const location = useLocation();
  const token =
    localStorage.getItem("auth_secret") && localStorage.getItem("userData");

  let urlElements = window.location.href.split("/");

  return (
    <>
      <ScrollToTop />
      <Switch>
        <UnAuthRoutes exact path="/sharepost" component={SharePostLogin} />
          <Route exact path="/delete-account" component={DeleteAccount} />
        <Route exact path="/delete-all-data" component={DeleteAccount} />
        <Route exact path="/feature" component={MainFeature}></Route>
        <Route exact path="/download" component={DownloadApp}></Route>
        <Route exact path="/guidelines" component={CommunityGuidelines}></Route>
        <Route
          exact
          path="/commercial-terms"
          component={CommercialTerms}
        ></Route>
        <Route exact path="/data-policy" component={DataPolicy}></Route>
        <Route exact path="/frequently-asked" component={FaqData}></Route>
        <Route exact path="/about" component={About} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/creator" component={Creator} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/jobs" component={Jobs} />
        <Route exact path="/beta" component={BetaPage} />
        <Route exact path="/job/:jobId" component={JobDetails} />
        <Route exact path="/earlyaccess" component={EarlyAccess} />

        <AuthProvider>
          <TagsProvider>
            <UserProvider>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="/signup">
                <Signup />
              </Route>

              {/* <Route exact path="/feed/:postID">
              <SharePost/>
              </Route> */}
              <Route
                path="*"
                render={(props) => {
                  return token ? (
                    <AuthRoutes path={"/"} component={PrivateLayout} />
                  ) : urlElements[3] === "feed" ? (
                    <UnAuthRoutes
                      exact
                      path="/feed/:postId"
                      component={SharePost}
                    />
                  ) : urlElements[3] === "profile" ? (
                    <Redirect to="/" />
                  ) : urlElements[3].includes("login") ? (
                    <Route path="/login" component={Login} />
                  ) : (
                    <Redirect to="/" />
                  );
                }}
              />
            </UserProvider>
          </TagsProvider>
        </AuthProvider>
        <UnAuthRoutes exact path="/" component={EarlyAccess} />

        <UnAuthRoutes
          exact
          path="/earlyaccess/validateverifyEmail"
          component={EarlyAccess}
        />
        <UnAuthRoutes exact path="/thankyou" component={ThankYou} />
        <UnAuthRoutes exact path="/feed/:postId" component={SharePost} />
      </Switch>
    </>
  );
};

export default Routes;
