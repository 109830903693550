import toUint8Array from "base64-to-uint8array";
import _crypto from "crypto";
import { PROFILE_IMG_URL } from "../../utils/config";

const decryptP = async (cipherType, { key, ciphertext, iv, aad }) => {
  // eslint-disable-next-line no-lone-blocks
  {
    let decipher;
    let input = Buffer.from(ciphertext);

    if (cipherType === "aes-256-gcm") {
      const gcm = _crypto.createDecipheriv(
        cipherType,
        Buffer.from(key),
        Buffer.from(iv)
      );
      if (input.length < 16) {
        throw new Error("Invalid GCM ciphertext");
      }
      const tag = input.slice(input.length - 16);
      input = input.slice(0, input.length - 16);
      gcm.setAuthTag(tag);
      if (aad) {
        gcm.setAAD(aad);
      }
      decipher = gcm;
    } else {
      // eslint-disable-next-line no-undef
      strictAssert(
        aad === undefined,
        `AAD is not supported for: ${cipherType}`
      );
      decipher = _crypto.createDecipheriv(
        cipherType,
        Buffer.from(key),
        Buffer.from(iv)
      );
    }

    return Buffer.concat([decipher.update(input), decipher.final()]);
  }
};

async function decryptAesGcm(key, iv, ciphertext) {
  return await decryptP("aes-256-gcm", { key, ciphertext, iv });
}

export const handleProfile = async (imagePath, profileKey) => {
  const url = `${PROFILE_IMG_URL}${imagePath}`;
  const readableStream = await fetch(url);

  const imageArrayBuffer = await readableStream.arrayBuffer();
  const keys = await toUint8Array(profileKey);
  const iv = imageArrayBuffer.slice(0, 12);
  const cipherText = imageArrayBuffer.slice(12, imageArrayBuffer.byteLength);

  if (keys.byteLength !== 32) {
    return {
      code: 412,
      data: "",
      message: "Got invalid length profile key",
    };
  }

  if (iv.byteLength !== 12) {
    return {
      code: 412,
      data: "",
      message: "Got invalid length profile iv",
    };
  }

  const binaryImage = await decryptAesGcm(keys, iv, cipherText);

  const base64Image = Buffer.from(binaryImage).toString("base64");

  return `data:image/jpeg;base64,${base64Image}`;
};
