import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar";
import { getPublicPageContent } from "../../publicApiCalls/Service";
import isEmpty from "is-empty";
import PublicPageSkeleton from "../../components/Skeleton/PublicPage";

export default function CommercialTerms() {
  const [contentData, setContentData] = useState({});
  const getPrivacyPolicyContentHanlder = async () => {
    const res = await getPublicPageContent("terms-and-conditions");
    if (res.code === 200) {
      setContentData(res.data);
    }
  };

  useEffect(() => {
    getPrivacyPolicyContentHanlder();
  }, []);
  return (
    <>
      <PublicNavbar />
      <div className="public_wrapper">
        <div className="body">
          <p style={{textAlign:"center", marginTop:"45px"}}>Commercial Terms Page</p>
          {!isEmpty(contentData?.content) ? (
            <Container>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${contentData.content}`,
                }}
              />
              {/* <h5>Our privacy principles</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ultricies mi eget mauris pharetra. Amet risus nullam eget felis
              eget nunc lobortis. Purus in massa tempor nec. Porta nibh
              venenatis cras sed. Viverra ipsum nunc aliquet bibendum enim.
              Risus pretium quam vulputate dignissim suspendisse in. Non quam
              lacus suspendisse faucibus interdum posuere lorem ipsum dolor.
              Facilisi cras fermentum odio eu feugiat pretium nibh ipsum. Risus
              sed vulputate odio ut enim blandit volutpat maecenas. Netus et
              malesuada fames ac turpis egestas. Sed arcu non odio euismod
              lacinia at quis risus sed. Praesent elementum facilisis leo vel
              fringilla est ullamcorper. Ullamcorper dignissim cras tincidunt
              lobortis feugiat vivamus. Elit pellentesque habitant morbi
              tristique senectus et netus.Morbi tincidunt augue interdum velit.
              Sed arcu non odio euismod lacinia at quis risus sed. Praesent
              elementum facilisis leo vel fringilla est ullamcorper. Ullamcorper
              dignissim cras tincidunt lobortis feugiat vivamus. Elit
              pellentesque habitant morbi tristique senectus et netus.Morbi
              tincidunt augue interdum velit. Sed arcu non odio euismod lacinia
              at quis risus sed. Praesent elementum facilisis leo vel fringilla
              est ullamcorper. Ullamcorper Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ultricies mi eget mauris pharetra.
              Amet risus nullam eget felis eget nunc lobortis. Purus in massa
              tempor nec. Porta nibh venenatis cras sed. Viverra ipsum nunc
              aliquet bibendum enim. Risus pretium quam vulputate dignissim
              suspendisse in. Non quam lacus suspendisse faucibus interdum
              posuere lorem ipsum dolor. Facilisi cras fermentum odio eu feugiat
              pretium nibh ipsum. Risus sed vulputate odio ut enim blandit
              volutpat maecenas. Netus et malesuada fames ac turpis egestas. Sed
              arcu non odio euismod lacinia at quis risus sed. Praesent
              elementum facilisis leo vel fringilla est ullamcorper. Ullamcorper
              dignissim cras tincidunt lobortis feugiat vivamus. Elit
              pellentesque habitant morbi tristique senectus et netus.Morbi
              tincidunt augue interdum velit. Sed arcu non odio euismod lacinia
              at quis risus sed. Praesent elementum facilisis leo vel fringilla
              est ullamcorper. Ullamcorper dignissim cras tincidunt lobortis
              feugiat vivamus. Elit pellentesque habitant morbi tristique
              senectus et netus.Morbi tincidunt augue interdum velit. Sed arcu
              non odio euismod lacinia at quis risus sed. Praesent elementum
              facilisis leo vel fringilla est ullamcorper. Ullamcorper Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ultricies mi
              eget mauris pharetra. Amet risus nullam eget felis eget nunc
              lobortis. Purus in massa tempor nec. Porta nibh venenatis cras
              sed. Viverra ipsum nunc aliquet bibendum enim. Risus pretium quam
              vulputate dignissim suspendisse in. Non quam lacus suspendisse
              faucibus interdum posuere lorem ipsum dolor. Facilisi cras
              fermentum odio eu feugiat pretium nibh ipsum. Risus sed vulputate
              odio ut enim blandit volutpat maecenas. Netus et malesuada fames
              ac turpis egestas. Sed arcu non odio euismod lacinia at quis risus
              sed. Praesent elementum facilisis leo vel fringilla est
              ullamcorper. Ullamcorper dignissim cras tincidunt lobortis feugiat
              vivamus. Elit pellentesque habitant morbi tristique senectus et
              netus.Morbi tincidunt augue interdum velit. Sed arcu non odio
              euismod lacinia at quis risus sed. Praesent elementum facilisis
              leo vel fringilla est ullamcorper. Ullamcorper dignissim cras
              tincidunt lobortis feugiat vivamus. Elit pellentesque habitant
              morbi tristique senectus et netus.Morbi tincidunt augue interdum
              velit. Sed arcu non odio euismod lacinia at quis risus sed.
              Praesent elementum facilisis leo vel fringilla est ullamcorper.
              Ullamcorper
            </p> */}
            </Container>
          ) : (
            <PublicPageSkeleton />
          )}
        </div>
      </div>
      <Footer extraItems />
    </>
  );
}
