/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@chakra-ui/react";
import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { CustomModal } from "../../components/UI/Modal";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";
import { Logo } from "../auth/shared/Logo";
import { useAuth } from "../../context/AuthContext";
import { capabilities } from "../../utils";

import ReCAPTCHA from "react-google-recaptcha";
import {
  accountTokenRefresh,
  accountVerifyLogin,
  userLogin,
} from "../auth/Service";
import isEmpty from "is-empty";
import { ClientJS } from "clientjs";
import { getUserProfile } from "../../components/layout/Service";
import LocalStorageService from "../../utils/LocalStorageService";
import { useUser } from "../../context/UserProvider";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";

const PostUserLoginModal = (props) => {
  const {
    firebaseToken,
    setAuthSecretHandler,
    getSH512Salt,
    getArgon2Password,
    argonPassword,
    toUTF8Array,
    setIsCaptchaShow,
    setAuthSecretAfterLoginHandler,
    setChallenge,
    // refreshToken,
    // setFirebaseToken,
    getChallengeHandler,
    // isChallengeFound,
  } = useAuth();
  const { onSubmitUserData } = useUser();

  const [isLogging, setIsLogging] = useState(false);
  const [userLoginDetail, setUserLoginDetail] = useState({});
  const { show, handleClose } = props;
  const [error, setError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [visitorId, setVisitorId] = useState("");
  const [showEye, setShowEye] = useState(false);

  const fpPromise = FingerprintJS.load({
    token: "vuYU13dLdP0lfsBy431V",
  });

  const handleChange = (e) => {
    const regEx = /[A-Z\s]+/;
    // eslint-disable-next-line eqeqeq
    if (e.target.name == "username" && regEx.test(e.target.value)) {
      setError(
        "Note: username doesn't contain any white space or capital Letter"
      );
    } else {
      const { name, value } = e.target;
      setUserLoginDetail((prev) => ({ ...prev, [name]: value }));
      if (name === "password") {
        toUTF8Array(value.trim());
      }
      setError("");
    }
  };

  const checkShaHandler = (e) => {
    const { value } = e.target;
    getSH512Salt(value.trim());
  };

  const getUserProfileHandler = async (uuid) => {
    const res = await getUserProfile(uuid);
    if (!isEmpty(res)) {
      LocalStorageService.setItem("userProfileData", res);
      const lastPath = localStorage.getItem("lastPath");
      if (lastPath) {
        return (window.location.href = lastPath);
      }
      window.location.href = "/feed";
      setIsLogging(false);
    }
  };
  const accountTokenRefreshHandler = async () => {
    const payload = {
      gcmRegistrationId: firebaseToken,
      webSocketChannel: true,
    };
    await accountTokenRefresh(payload);
  };
  const accountVerifyCaptchaLoginHandler = async (type) => {
    const authKeyTemp = localStorage.getItem("secret");
    const payload = {
      capabilities,
      discoverableByPhoneNumber: true,
      fetchesMessages: true,
      pin: null,
      registrationId: 0,
      registrationLock: null,
      signalingKey: null,
      unidentifiedAccessKey: "",
      unrestrictedUnidentifiedAccess: false,
      video: true,
      voice: true,
      isLogin: true,
      password: argonPassword,
      AuthKey: authKeyTemp,
    };

    if (type === "NO_TOKEN") {
      payload.challenge = type;
    } else {
      payload.captcha = captchaValue;
    }

    const res = await accountVerifyLogin(userLoginDetail.username, payload);

    if (res?.response?.status === 402) {
      return setIsCaptchaShow(true);
    } else if (res?.response?.status === 401) {
      setIsLogging(false);
      if (type === "NO_TOKEN") {
        return;
      }
      return setError("Invalid credentials!");
    }
    if (!isEmpty(res)) {
      setTimeout(() => {
        setIsLogging(false);
        localStorage.setItem("isTokenValid", true);
        setAuthSecretAfterLoginHandler(res?.uuid);
        getUserProfileHandler(res?.uuid);
        setChallenge("");
        onSubmitUserData(res);
        accountTokenRefreshHandler();
        LocalStorageService.setItem("userData", res);
      }, 500);
    } else {
      setError("Something went wrong!");
      setIsLogging(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = userLoginDetail;
    try {
      setError("");

      setIsLogging(true);

      const salt = getSH512Salt(password.trim());
      getArgon2Password(salt);
      const clientJs = new ClientJS();
      var browserVersion = clientJs.getBrowserVersion(); // Get Browser Version

      const payload = {
        token: firebaseToken ? firebaseToken : "NO_FIREBASE_TOKEN",
        username,
        deviceId: visitorId,
        deviceName: `${clientJs.getBrowser()}_${browserVersion.replaceAll(
          ".",
          "_"
        )}`,
        country: "IN",
      };
      getChallengeHandler();

      const res = await userLogin(payload);
      if (!isEmpty(res?.response)) {
        setIsLogging(false);
        return setError(res?.response?.data?.message);
      }
      accountVerifyCaptchaLoginHandler();
    } catch (error) {
      setError("Failed to log in", "checkFaild");
    }
  };
  const handleCaptchaChange = (value) => {
    const { password } = userLoginDetail;
    const salt = getSH512Salt(password.trim());
    getArgon2Password(salt);
    setCaptchaValue(value);
  };

  useEffect(() => {
    // Get the visitor identifier when you need it.
    const fetchId = () => {
      fpPromise
        .then((fp) => fp.get())
        .then((result) => setVisitorId(result.visitorId));
    };

    fetchId();
  }, []);

  useEffect(() => {
    if (captchaValue) {
      setAuthSecretHandler(userLoginDetail.username);
      // setTimeout(() => {
      //   accountVerifyCaptchaLoginHandler();
      // }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaValue]);

  const handleEye = () => {
    setShowEye(!showEye);
    const res = document.getElementById("password123");
    if (res.type === "password") {
      res.type = "text";
    } else {
      res.type = "password";
    }
  };
  return (
    <CustomModal
      crossIcon={false}
      className="Login_share_post_modal"
      show={show}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Logo />
      </div>

      <div style={{ position: "absolute", right: "10px", top: "4px" }}>
        {" "}
        <i
          className="bi bi-x"
          style={{ "font-size": "30px" }}
          onClick={handleClose}
        ></i>
      </div>
      <div
        className="modal_container"
        style={{
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "12px",
        }}
      >
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3 text-start">
              <Form.Label style={{ paddingRight: "200px" }}>
                Username
              </Form.Label>
              <Form.Control
                type="text"
                name="username"
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 text-start">
              <Form.Label style={{ paddingRight: "200px" }}>
                Password
              </Form.Label>
              <Form.Control
                type="password"
                id="password123"
                name="password"
                onChange={handleChange}
                required
                onBlur={checkShaHandler}
              />
              <p
                className={`icon ${showEye ? "eye" : "eye_hidden"}`}
                onClick={(e) => handleEye(e)}
                style={{
                  position: "absolute",
                  right: "47px",
                  top: "43%",
                  cursor: "pointer",
                }}
              ></p>
            </Form.Group>
            {error && <p className="text-danger text-center mb-2">{error}</p>}
            {/* {isCaptchaShow && ( */}
            {userLoginDetail.username !== "" &&
              userLoginDetail.password !== "" && (
                <div className="captcha_wrap login">
                  <ReCAPTCHA
                    // use this for local use
                    // sitekey="6LczLvUcAAAAAAQJjHd-gmGMMlb3c3ulOEHoFeVX"
                    sitekey="6Lf-0i0hAAAAANh6DrDTbe4KikGJHiC1CBeVsXRR"
                    // use this for production
                    // sitekey="6LdBbogdAAAAAKdbiCilq7pEGQ2qUplcDFCVVyIL"
                    onChange={handleCaptchaChange}
                    size="normal"
                  />
                </div>
              )}
            {/* )} */}
            <Button
              isDisabled={error || !captchaValue}
              isLoading={isLogging}
              loadingText="Loging"
              type="submit"
              style={{
                width: "278px",
                height: "35.94px",
                borderRadius: "278px",
                fontSize: "14px",
                backgroundColor: "#D8463D",
              }}
            >
              {" "}
              Log In
            </Button>
          </Form>
          <div style={{ marginTop: "12.19px", fontSize: "12px" }}>
            New to Cachy? Signup using our Mobile app.
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "37.8px", color: "#707070" }}>
          <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
            <img
              src={PlayStore}
              width="123.15px"
              height="36.28px"
              alt="app store"
            />
          </a>
          <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
            <img
              className="ml-35"
              width="123.15px"
              height="36.28px"
              src={AppStore}
              alt="app store"
            />
          </a>
        </div>
      </div>
    </CustomModal>
  );
};

export default PostUserLoginModal;
